/*
    In order to make this mixin work in all browsers you need to encode your svg element, and only the SVG element, starting from <svg>... till </svg>
    Read about this issue here: http://stackoverflow.com/questions/10768451/inline-svg-in-css

    how to use: content: inline-svg(string);
*/

@use '../functions/inline-svg';

@mixin svg($type, $color) {
    @if $type == arrow-up {
        content: inline-svg.inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" viewBox="0 0 29 32"><path d="M11.624 32V11.014l-7.55 7.542L0 14.478 14.474 0l14.478 14.482-4.078 4.078-7.487-7.487V32z"/></svg>');
    } @else if $type == caret-down {
        content: inline-svg.inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" viewBox="0 0 32 21"><path d="M.187 1.64l15.292 19.004c.253.313.788.313 1.04 0L31.815 1.639A.936.936 0 0 0 31.89.633a.668.668 0 0 0-.588-.348H.697A.666.666 0 0 0 .11.633.94.94 0 0 0 .187 1.64z"/></svg>');
    } @else if $type == close {
        content: inline-svg.inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" viewBox="0 0 357 357"><path d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z"/></svg>');
    } @else if $type == search {
        content: inline-svg.inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" viewBox="0 0 32 32"><path d="M29.143 32L18.187 21.052a11.515 11.515 0 0 1-6.62 2.08C5.183 23.132 0 17.954 0 11.567 0 5.186 5.183 0 11.567 0c6.385 0 11.565 5.186 11.565 11.567 0 2.457-.766 4.734-2.073 6.606l10.958 10.955L29.143 32zm-8.516-20.433c0-4.998-4.063-9.057-9.06-9.057-5.003 0-9.059 4.059-9.059 9.057 0 5 4.056 9.062 9.06 9.062 4.996 0 9.059-4.063 9.059-9.062z"/></svg>');
    }
}
