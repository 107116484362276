@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';

.pagination {
    &__header {
        margin: 4rem $p-container .7rem;

        @include screen.apply($screen-simple) {
            margin: 4rem 0 .7rem;
        }
    }

    &__heading {
        @include typography.heading-1;
    }

    &__showmore {
        padding: 2rem 0 4rem;
        text-align: center;

        &.is-hidden {
            display: none;
        }
    }

    &__no-results {
        padding: 3rem 1.5rem;
    }
}
