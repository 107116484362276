@use '../config' as *;
@use '../mixins/screen';

.video {
    &__close {
        padding: .6rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        &:hover {
            background: $c-overlay-btn-hover;
        }

        &-icon {
            fill: $c-white;
            height: 20px;
            width: 20px;
        }

        @include screen.apply($screen-simple) {
            &-icon {
                height: 30px;
                width: 30px;
            }
        }
    }

    &__container {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 2rem);

        @include screen.apply($screen-minimal) {
            max-width: 390px;
        }

        @include screen.apply($screen-simple) {
            max-width: 560px;
        }
    }

    &__container-inner {
        padding-top: 56.25%;
    }

    &__overlay {
        background: $c-overlay;
        height: 100%;
        inset: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 180ms;
        z-index: 2;

        .is-active & {
            opacity: 1;
            pointer-events: all;

        }
    }

    &__play-btn {
        cursor: pointer;
        left: 50%;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 180ms, width .2s;
        width: 100px;

        &:hover {
            width: 125px;
        }

        .is-active & {
            opacity: 0;
        }

        &-icon {
            fill: $c-white;
            height: auto;
            width: 100%;
        }
    }

    &__player {
        height: 100%;
        inset: 0;
        opacity: 0;
        position: absolute;
        transform: translateY(-50px);
        transition: opacity 250ms, transform 180ms;
        width: 100%;

        &.is-loaded {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
