@use '../config' as *;
@use '../mixins/list-reset';
@use '../mixins/typography';
@use '../mixins/property-override';

.search-results {
    padding: 2rem $p-container;

    &__list {
        @include list-reset.apply;

        max-width: 800px;
    }

    &__item {
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        position: relative;

        &::before {
            background-color: $c-black;
            content: '';
            display: block;
            font-size: 1em;
            height: 12px;
            left: 0;
            position: absolute;
            top: 7px;
            width: 12px;
        }
    }

    &__link {
        color: $c-black;
    }

    &__title {
        @include typography.heading-3;

        @include property-override.apply {
            color: $c-blue;
            margin-bottom: .3rem;
        }
    }

    &__category {
        @include typography.heading;
        @include typography.heading-5;

        @include property-override.apply {
            margin-bottom: .3rem;
        }
    }

    &__description {
        font-size: 1rem;
        margin-bottom: .8rem;
    }

    &__no-results {
        @include typography.heading;
        @include typography.heading-4;

        @include property-override.apply {
            margin-bottom: 2rem;
        }
    }
}
