@use 'sass:color';
@use '../config' as *;
@use '../mixins/animations';
@use '../mixins/screen';

:root {
    --drawer-search-background-color: #{ $c-blue };
    --drawer-search-color: #{ $c-white };
    --drawer-search-column-gap: 4rem;
    --drawer-search-close-background-color: #{ $c-white };
    --drawer-search-close-background-hover-color: #{ $c-blue--light };
    --drawer-search-close-icon-color: #{ $c-blue };
    --drawer-search-close-icon-hover-color: #{ $c-white };
    --drawer-search-header-logo-width: 75px;
}

.drawer-search {
    $self: &;

    background-color: var(--drawer-search-background-color);
    border: none;
    color: var(--drawer-search-color);
    height: fit-content;
    left: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 10;

    &[open] {
        @include animations.fade-in($transition-speed-slow);
    }

    &.is-closed {
        @include animations.fade-out($transition-speed-slow);
    }

    &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: $w-container;
        padding: 0 $p-container $p-container;
        width: 100%;
    }

    &__header {
        align-items: center;
        display: flex;
        height: $h-header;
        justify-content: space-between;
    }

    &__link {
        display: block;
    }

    &__logo {
        display: none;
        width: var(--drawer-search-header-logo-width);
    }

    &__logo-text {
        height: $h-logo-text !important;
        width: $w-logo-text !important;
    }

    &__close {
        align-items: center;
        background-color: var(--drawer-search-close-background-color);
        border-radius: 0;
        display: flex;
        height: $h-button;
        justify-content: center;
        padding: 0;
        width: $h-button;
    }

    &__icon-close {
        fill: var(--drawer-search-close-icon-color);
        height: $h-icon;
        width: $h-icon;
    }

    @include screen.apply($screen-large) {
        &__header {
            justify-content: flex-end;
            position: relative;
        }

        &__branding {
            left: calc(var(--drawer-search-header-logo-width) * -1 - 4rem);
            position: absolute;
            top: 6px;
        }

        &__logo {
            display: block;
        }

        &__logo-text {
            display: none;
        }
    }
}
