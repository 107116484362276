@use '../config' as *;
@use '../mixins/list-reset';
@use '../mixins/typography';

.social {
    margin-bottom: 1rem;

    &__title {
        @include typography.small;

        margin-bottom: .5rem;

        &--hide {
            visibility: hidden;
        }
    }

    &__list {
        @include list-reset.apply;
    }

    &__item {
        display: inline-block;
        margin-right: 1px;
        transition: background-color .2s;
        vertical-align: top;

        &:hover {
            background-color: $c-blue;
        }
    }

    &__link {
        display: flex;
        height: 32px;
        width: 32px;
    }

    &__icon {
        fill: $c-white;
        height: 16px;
        margin: auto;
        width: 16px;

        &--youtube {
            height: 20px;
            width: 20px;
        }
    }

    &.theme--dark-grey {
        background: transparent;

        .social__title {
            color: $c-grey--dark;
        }

        .social__item {
            background-color: $c-grey--dark;

            &:hover {
                background-color: $c-blue;
            }
        }
    }


    &.theme--white {
        background: transparent;

        .social__item {
            background-color: $c-white;

            &:hover {
                background-color: $c-blue;
            }
        }
    }
}
