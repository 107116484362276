@use 'sass:math';
@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';

.text-block {
    overflow: hidden;
    position: relative;
    width: 100%;

    @include screen.apply($screen-normal) {
        min-height: 333px;

        &--medium {
            min-height: 500px;
        }
    }

    &--hover {
        &:hover {
            .text-block__image {
                transform: translate(-50%, -50%);

                @include screen.apply($screen-simple) {
                    transform: scale(1.25);
                    transition: transform 1.5s;
                }
            }
        }
    }

    &__btn {
        color: inherit;
        position: relative;

        &::before {
            background-color: inherit;
        }
    }

    &__content {
        margin-bottom: 2.1rem;
        max-width: 800px;
        overflow: hidden;
        padding: 0 1.5rem;
        width: 100%;

        @include screen.apply($screen-normal) {
            margin-bottom: 2.5rem;
        }
    }

    &__media {
        display: block;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        width: 100%;

        @include screen.apply($screen-minimal) {
            padding-bottom: 50%;
        }

        @include screen.apply($screen-normal) {
            height: 100%;
            overflow: hidden;
            padding-bottom: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
        }
    }

    &__image {
        display: block;
        left: 50%;
        margin: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include screen.apply($screen-simple) {
            left: 0;
            top: 0;
            transform: translate(0);
        }

        @include screen.apply($screen-normal) {
            left: auto;
            position: static;
            top: auto;
        }
    }

    &__header {
        padding: 1.75rem 0 1rem;
        position: relative;
    }

    &__heading {
        @include typography.heading-block;
    }

    &__subheading {
        font-size: .9rem;
        margin-bottom: .3rem;
    }

    &__footer {
        bottom: 0;
        padding: 1.5rem 1.75rem;
        position: absolute;
        width: 100%;
    }

    &__link {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__text {
        position: relative;
    }

    &.text-block--img {
        @include screen.apply($screen-normal) {
            .text-block__content {
                bottom: 0;
                left: 0;
                position: absolute;
                top: 0;
                width: 50%;
            }

            .text-block__media {
                display: block;
                height: 100%;
                width: 50%;
            }

            &.text-block--img-left {
                .text-block__content {
                    left: auto;
                    right: 0;
                }

                .text-block__media {
                    left: 0;
                    right: auto;
                }
            }

            &.text-block--small {
                padding-bottom: math.div($w-container, 3);

                .text-block__content {
                    width: math.div($w-container, 3) * 2;
                }

                .text-block__media {
                    width: math.div($w-container, 3);
                }

                .text-block--img {
                    .text-block__content {
                        width: math.div($w-container, 3) * 2;
                    }

                    .text-block__media {
                        width: math.div($w-container, 3);
                    }
                }
            }

            &.text-block--medium {
                padding-bottom: $w-container * 0.5;

                .text-block__content {
                    width: $w-container * 0.5;
                }

                .text-block__media {
                    width: $w-container * 0.5;
                }

                .text-block__heading {
                    font-size: 2rem;
                }

                .text-block__subheading {
                    font-size: 1.3rem;
                }

                .text-block--img {
                    .text-block__content {
                        width: $w-container * 0.5;
                    }

                    .text-block__media {
                        width: $w-container * 0.5;
                    }
                }
            }

            &.text-block--large {
                padding-bottom: math.div($w-container, 3);

                .text-block__content {
                    width: math.div($w-container, 3);
                }

                .text-block__media {
                    width: math.div($w-container, 3) * 2;
                }

                .text-block--img {
                    .text-block__content {
                        width: math.div($w-container, 3);
                    }

                    .text-block__media {
                        width: math.div($w-container, 3) * 2;
                    }
                }
            }
        }
    }
}
