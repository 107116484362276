@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.banner {
    $self: &;

    margin-bottom: -1px;
    min-height: 190px;
    overflow: hidden;
    position: relative;

    &--small {
        min-height: calc((#{$screen-simple}) / 3);
        padding-bottom: 0;
    }

    &__breadcrumbs {
        bottom: 0;
        max-width: 100%;
        position: absolute;
    }

    &__heading {
        @include typography.heading-1;

        @include property-override.apply {
            margin-bottom: 1rem;
            padding: 0;
        }
    }

    &__subheading {
        @include typography.heading-1;

        @include property-override.apply {
            color: $c-blue;
            padding: 0;
            width: 80%;
        }
    }

    &__content {
        max-width: 800px;
        padding: 1.75rem $p-container 2.5rem;
        width: 100%;

        #{ $self }--small & {
            height: auto;
            position: relative;
            top: auto;
        }
    }

    &__text {
        font-size: 1.125rem;

        blockquote {
            text-align: left;
        }

        &--small {
            width: 60%;
        }
    }

    &__media {
        height: 100%;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__image {
        display: block;
        left: 50%;
        margin: auto;
        max-width: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;

        &--video {
            height: 100%;
            width: auto;
        }
    }

    &__link {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__button {
        margin-top: 1rem;
    }

    &__icon {
        height: auto;
        width: 100%;
    }

    &__play-btn {
        cursor: pointer;
        left: 75%;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 180ms, width .2s;
        width: 100px;
    }

    &.theme--blue {
        #{ $self }__subheading {
            color: $c-black;
        }
    }

    &.theme--light-blue {
        #{ $self }__subheading {
            color: $c-white;
        }
    }

    &.theme--grey {
        #{ $self }__subheading {
            color: $c-white;
        }
    }

    &--image {
        color: $c-white;
        text-shadow: 0 0 30px $shadow-text;

        #{ $self }__content {
            height: 100%;
            position: absolute;
            top: 0;
        }

        &.theme--blue,
        &.theme--light-blue,
        &.theme--grey {
            #{ $self }__subheading {
                color: $c-blue;
            }
        }
    }

    &--video {
        color: $c-white;
        text-shadow: 0 0 30px $shadow-text;

        &.theme--blue,
        &.theme--light-blue,
        &.theme--grey {
            #{ $self }__subheading {
                color: $c-blue;
            }
        }
    }

    &--hover {
        cursor: pointer;

        &:hover {
            #{ $self }__image {
                transform: scale(1.25);
                transition: transform 1.5s;
                transition-timing-function: ease-in-out;
            }
        }
    }

    @include screen.apply($screen-minimal) {
        min-height: 300px;

        &--image {
            min-height: 300px;
        }
    }

    @include screen.apply($screen-simple) {
        min-height: 0;
        padding-bottom: 40%;

        &__content {
            height: 100%;
            position: absolute;
            top: 0;
        }

        &__image {
            left: auto;
            position: static;
            top: auto;
            transform: translate(0, 0);
            width: 100%;

            &--video {
                height: auto;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }

        &--image {
            min-height: 300px;
        }

        &--small {
            min-height: calc((#{$screen-simple}) / 3);
            padding-bottom: 0;
        }
    }

    @include screen.apply($screen-normal) {
        &__text{
            &--small {
                width: 80%;
            }
        }

        &__image {
            display: block;
            left: 50%;
            margin: auto;
            max-width: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;

            &--video {
                height: auto;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }

        &--hover{
            &:hover {
                #{ $self }__image{
                    transform: translate(-50%, -50%) scale(1.25);
                }
            }
        }
    }
}
