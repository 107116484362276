@use '../config' as *;
@use '../mixins/typography';
@use '../mixins/property-override';

.tag {
    @include typography.heading;

    @include property-override.apply {
        align-items: center;
        display: flex;
    }

    &__icon {
        background: $c-blue;
        display: inline-block;
        height: 24px;
        position: relative;
        transition: background .3s;
        width: 24px;
    }

    &__svg {
        fill: $c-white;
        height: 16px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: fill .3s;
        width: 16px;
    }

    &__text {
        color: $c-black;
        margin-left: .75rem;
        transition: color .3s;
    }

    &__close {
        display: none;
    }

    &:hover {
        .tag__text {
            color: $c-black;
        }
    }

    &--button {
        padding: .4rem .6rem .4rem .4rem;
        transition: background .3s;

        &.is-active {
            background: $c-blue;

            .tag__icon {
                background: $c-white;
            }

            .tag__svg {
                fill: $c-blue;
            }

            .tag__text {
                color: $c-white;
            }

            &:hover {
                .tag__text {
                    color: $c-white;
                }
            }
        }
    }

    &--removable {
        padding: 0;

        .tag__icon {
            display: none;
        }

        .tag__close {
            display: block;
            fill: $c-white;
            height: 14px;
            transition: fill .3s;
            width: 14px;
        }
    }

    &--reversed {
        .tag__icon {
            background: $c-white;
        }

        .tag__svg {
            fill: $c-blue;
        }

        .tag__text {
            color: $c-black;
        }

        &:hover {
            .tag__text {
                color: $c-white;
            }
        }

        &.tag--button {
            &.is-active {
                background: $c-white;

                .tag__icon {
                    background: $c-blue;
                }

                .tag__svg {
                    fill: $c-white;
                }

                .tag__text {
                    color: $c-black;
                }

                &:hover {
                    .tag__text {
                        color: $c-black;
                    }
                }
            }
        }
    }
}
