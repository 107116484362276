@use '../config' as *;
@use '../mixins/animations';
@use '../mixins/screen';

.media-blocks {
    $self: &;

    margin: 0 auto;

    &__item {
        > :first-child {
            padding-bottom: 100%;
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        &__item {
            opacity: 0;
        }

        &.in-view {
            --vertical-animation-translate: 10%;

            #{ $self }__item {
                @include animations.fade-in-vertical('to-top', $transition-speed-slow);
            }

            @for $i from 1 through 100 {
                #{ $self }__item {
                    &:nth-child(#{ $i }) {
                        animation-delay: ($i - 1) * .15s;
                    }
                }
            }
        }
    }

    @include screen.apply($screen-minimal) {
        &__item {
            > :first-child {
                padding-bottom: 50%;
            }
        }
    }

    @include screen.apply($screen-simple) {
        display: flex;
        flex-wrap: wrap;

        &__item {
            flex: 1 1 50%;
            width: 50%;

            > :first-child {
                padding-bottom: 100%;
            }
        }
    }

    @include screen.apply($screen-simple, $screen-normal) {
        &--33-33-33 {
            #{ $self }__item {
                &:last-child {
                    > :first-child {
                        padding-bottom: 50%;
                    }
                }
            }
        }
    }

    @include screen.apply($screen-normal) {
        flex-wrap: nowrap;

        &__item {
            &--33 {
                flex-basis: 33.33%;
                width: 33.33%;
            }

            &--50-sm {
                > :first-child {
                    padding-bottom: 66.66%;
                }
            }

            &--66 {
                flex-basis: 66.66%;
                width: 66.66%;

                > :first-child {
                    padding-bottom: 50%;
                }
            }
        }
    }
}
