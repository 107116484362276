@use '../mixins/stretch';

.media {
    $self: &;

    display: block;
    position: relative;

    &--video {
        height: 0;
        padding-top: 56.25%;
        position: relative;

        embed,
        object,
        iframe {
            @include stretch.apply;
        }
    }
}
