@use '../mixins/typography';

.section {
    &.event-details {
        height: 0;
        padding: 10px 0 100%;
        width: 100%;

        .btn {
            margin-left: 10px;
        }
    }
}

.event-info {
    font-size: 1rem;
    margin-bottom: 20px;
    padding: 0 10px;

    &--heading {
        @include typography.heading;
    }

    &__label {
        display: block;
        width: 100px;
    }

    &__value {
        font-weight: bold;
    }
}
