@mixin apply {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }

    .legacy & {
        &::before,
        &::after {
            zoom: 1;
        }
    }
}
