@use 'sass:color';
@use '../config' as *;
@use '../mixins/typography';

.btn {
    $self: &;

    background-color: $c-blue;
    border-radius: 2px;
    color: $c-white;
    display: inline-block;
    font-weight: typography.$weight-bold;
    height: $h-button;
    letter-spacing: 1px;
    line-height: $h-button;
    padding: 0 $p-button;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: .2s background-color;

    &:hover {
        background-color: color.adjust($c-blue, $lightness: -10%);
        color: $c-white;
    }

    &__icon {
        fill: $c-white;
        height: 16px;
        width: 16px;
    }

    &--text {
        background: transparent;
        border: 0;
        color: $c-white;
        font-size: .9rem;
        height: auto;
        line-height: inherit;
        padding: 0 0 0 1.4rem;
        text-align: left;

        &::before {
            background: $c-white;
            content: '';
            display: block;
            height: 12px;
            left: 0;
            position: absolute;
            top: 1px;
            transition: transform .3s cubic-bezier(0, 0, .2, 1);
            width: 12px;
        }

        &::after {
            border-bottom: solid 2px color.adjust($c-white, $alpha: -.3);
            content: '';
            display: block;
            opacity: 0;
            position: relative;
            transform: translateY(0);
            transition: opacity .3s cubic-bezier(0, 0, .2, 1);
        }

        &:hover {
            background: transparent;

            &::before {
                transform: rotate(90deg);
            }

            &::after {
                opacity: 1;
            }
        }

        &#{ $self }--blue {
            color: $c-blue;

            &::before {
                background: $c-blue;
            }

            &::after {
                border-bottom: solid 2px color.adjust($c-blue, $alpha: -.3);
            }

        }
    }

    &--search {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        flex: 0 0 auto;
    }

    &--lightblue {
        background-color: $c-blue--light;

        &:hover {
            background-color: color.adjust($c-blue--light, $lightness: -10%);
        }
    }

    &--grey {
        background-color: $c-grey;

        &:hover {
            background-color: color.adjust($c-grey, $lightness: -10%);
        }
    }

    &--darkgrey {
        background-color: $c-grey--dark;

        &:hover {
            background-color: color.adjust($c-grey--dark, $lightness: -10%);
        }
    }

    &--white {
        background-color: $c-white;
        color: $c-blue;

        #{ $self }__icon {
            fill: $c-blue;
        }

        &:hover {
            background-color: color.adjust($c-white, $lightness: -10%);
        }
    }

    &--outline {
        background: $c-white;
        border: solid 1px $c-blue;
        color: $c-blue;
        padding: 0 15px;

        #{ $self }__icon {
            fill: $c-blue;
        }

        &:hover {
            background: $c-blue;

            #{ $self }__icon {
                fill: $c-white;
            }
        }

        &#{ $self }--lightblue {
            border-color: $c-blue--light;
            color: $c-blue--light;

            #{ $self }__icon {
                fill: $c-blue--light;
            }

            &:hover {
                background-color: color.adjust($c-blue--light, $lightness: -10%);
                color: $c-white;

                #{ $self }__icon {
                    fill: $c-white;
                }
            }
        }

        &#{ $self }--grey {
            border-color: $c-grey;
            color: $c-grey;

            #{ $self }__icon {
                fill: $c-grey;
            }

            &:hover {
                background-color: $c-grey;
                color: $c-white;

                #{ $self }__icon {
                    fill: $c-white;
                }
            }
        }

        &#{ $self }--darkgrey {
            border-color: $c-grey--dark;
            color: $c-grey--dark;

            #{ $self }__icon {
                fill: $c-grey--dark;
            }

            &:hover {
                background-color: $c-grey--dark;
                color: $c-white;

                #{ $self }__icon {
                    fill: $c-white;
                }
            }
        }
    }
}
