@use '../config' as *;

@mixin fade-in(
    $duration: $transition-speed-fast,
    $delay: 0ms
) {
    animation: fade-in $duration $delay forwards;
}

@mixin fade-in-vertical(
    $direction: 'to-top', // options: 'to-top'
    $duration: $transition-speed-mid,
    $delay: $transition-delay-short
) {
    animation: fade-in-#{ $direction } $transition-ease-out $duration $delay forwards;
}

@mixin fade-out(
    $duration: $transition-speed-fast,
    $delay: 0ms
) {
    animation: fade-out $duration $delay forwards;
}

@mixin slide-in(
    $direction: 'to-right', // options: 'to-left', 'to-right', 'to-bottom'
    $duration: $transition-speed-slow,
    $delay: 0ms
) {
    animation: slide-in-#{ $direction } $transition-ease-in $duration $delay;
}

@mixin slide-out(
    $direction: 'to-right', // options: 'to-left', 'to-right', 'to-top'
    $duration: $transition-speed-slow,
    $delay: 0ms
) {
    animation: slide-out-#{ $direction } $transition-ease-out $duration $delay;
}

// KEYFRAMES

@keyframes slide-in-to-right {
    from {
        transform: translateX(-110%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-in-to-left {
    from {
        transform: translateX(110%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-in-to-bottom {
    from {
        transform: translateY(-110%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-out-to-right {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(110%);
    }
}

@keyframes slide-out-to-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-110%);
    }
}

@keyframes slide-out-to-top {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-110%);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in-to-top {
    0% {
        opacity: 0;
        transform: translate(var(--horizontal-animation-translate, 0), var(--vertical-animation-translate, 100%));
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
