@use '../config' as *;
@use '../mixins/typography';
@use '../mixins/property-override';

.breadcrumbs {
    background-color: $c-grey;
    display: flex;
    font-size: 13px;

    &__back {
        background-color: $c-grey--dark;
        flex: 0 0 auto;
        font-size: 23px;
        font-weight: typography.$weight-bold;
        height: 38px;
        line-height: 38px;
        position: relative;
        text-align: center;
        transition: background-color .2s;
        width: 38px;

        &:hover {
            background-color: $c-black;
        }
    }

    &__links {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        padding: 0 .8rem;
    }

    &__link {
        @include typography.heading;

        @include property-override.apply {
            background-color: $c-grey;
            color: $c-white;
            flex: 0 0 auto;
            font-size: .9rem;
            height: 38px;
            line-height: 38px;
            text-transform: uppercase;
            transition: color .2s;
            white-space: nowrap;
        }

        &::before {
            content: '/';
            padding: 0 .4rem;
        }

        &:hover {
            color: $c-black;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }
    }

    &__icon {
        fill: $c-white;
        height: 18px;
        left: 48%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
    }
}
