@use 'sass:color';
@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/sr-only';
@use '../mixins/stretch';
@use '../mixins/svg';

.popover {
    $self: &;

    inset: 0;
    opacity: 0;
    position: fixed;
    transition: opacity .2s;
    z-index: 50;

    &__mask {
        @include stretch.apply;

        background-color: color.adjust($c-black, $alpha: -.2);
    }

    &__content {
        left: 50%;
        margin: 0 auto;
        max-height: 100%;
        max-width: $w-container-sm;
        overflow: hidden auto;
        padding: 50px $p-container $p-container;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    &__close {
        background-color: transparent;
        cursor: pointer;
        height: 40px;
        padding: .6rem;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color .2s;
        width: 40px;
        z-index: 2;

        &::before {
            @include svg.svg('close', $c-white);
        }

        span {
            @include sr-only.apply;
        }

        &:hover {
            background-color: $c-black;
        }
    }

    &.is-active {
        opacity: 1;
    }

    &--close-hidden {
        .popover__close {
            display: none;
        }
    }

    &--sm {
        .popover__content {
            max-width: 540px;
        }
    }

    &--wide {
        #{ $self }__content {
            max-width: $w-container-xl;
        }
    }

    .media {
        &--video {
            opacity: 0;
            transform: translateY(-100px);
            transition: opacity .1s, transform .2s;

            &.is-loaded {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .donate-widget {
        margin: 0 auto;
    }

    @include screen.apply($screen-simple) {
        &__close {
            height: 50px;
            padding: .8rem;
            width: 50px;
        }
    }

    @include screen.apply($screen-normal) {
        &__content {
            padding: $p-container;
        }
    }
}
