@use '../config' as *;
@use '../mixins/typography';

.form {
    body {
        background-color: $c-white;
    }

    .section {
        clear: left;
    }

    .corner {
        background: $c-white;
        border-radius: 0;
        margin: 0;
        padding: 10px 25px 5px;
        width: 550px;
    }

    h2 {
        color: $c-grey--dark;
        font-size: 1.125rem;
        margin: 0;
        text-transform: uppercase;
    }

    td {
        border: unset;
        text-align: left;
    }

    .rightalligned {
        text-align: right;
    }

    .field {
        font-size: 1rem;
    }

    .field418af6b1-de7e-4adf-a98c-1ec9a8ea639a {
        display: none;
    }

    input {
        border: 2px solid $c-grey;
        color: $c-grey--dark;
        max-width: 500px;
        outline: none;
        padding: .5rem;
        transition: border .2s ease;

        &:focus {
            border-color: $c-grey--dark;
        }
    }

    select {
        border: 2px solid $c-grey;
        color: $c-grey--dark;
        max-width: 500px;
        outline: none;
        padding: .5rem;
        transition: border .2s ease;
    }

    label {
        color: $c-grey--dark;
        font-size: 1rem;
        font-weight: typography.$weight-bold;
        margin-bottom: .5rem;
    }

    .helptext {
        color: $c-black;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 1rem;
    }

    .button {
        background-color: $c-blue;
        border: 0;
        border-radius: 2px;
        color: $c-white;
        cursor: pointer;
        display: inline-block;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: typography.$weight-bold;
        height: 32px;
        line-height: 32px;
        margin: 5px 5px 0 0;
        padding: 0 30px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: ease .3s;
        vertical-align: top;
        white-space: nowrap;
        width: auto;

        &:hover {
            background-color: $c-grey--dark;
        }
    }

    .error {
        border-color: $c-red;
        border-style: solid;
        border-width: 1px;
    }
}
