@use 'sass:color';
@use '../config' as *;

// stylelint-disable scss/at-mixin-pattern
// Blue
@mixin blue {
    background-color: $c-blue;
    color: $c-white;
    transition: background-color .2s, color .2s;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-white;
    }

    a {
        &:not([class]) {
            color: $c-white;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    blockquote > p {
        color: $c-black
    }
}

@mixin blue-hover {
    &:hover {
        background-color: color.adjust($c-blue, $lightness: 10%);
    }
}

// Blue - light
@mixin light-blue {
    background-color: $c-blue--light;
    color: $c-black;
    transition: background-color .2s, color .2s;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-black;
    }

    a {
        &:not([class]) {
            color: $c-black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    blockquote > p {
        color: $c-white
    }
}

@mixin light-blue-hover {
    &:hover {
        background-color: color.adjust($c-blue--light, $lightness: -10%);
    }
}

// Blue - lightest
@mixin lightest-blue {
    background-color: $c-blue--lightest;
    color: $c-black;
    transition: background-color .2s, color .2s;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-black;
    }

    a {
        &:not([class]) {
            color: $c-black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@mixin lightest-blue-hover {
    &:hover {
        background-color: color.adjust($c-blue--lightest, $lightness: -10%);
    }
}

// Grey
@mixin grey {
    background-color: $c-grey;
    color: $c-black;
    transition: background-color .2s, color .2s;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-white;
    }

    a {
        &:not([class]) {
            color: $c-black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    blockquote > p {
        color: $c-white
    }
}

@mixin grey-hover {
    &:hover {
        background-color: color.adjust($c-grey, $lightness: -10%);
    }

}

// Grey - light
@mixin light-grey {
    background-color: $c-grey--light;
    color: $c-black;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-black;
    }

    a {
        &:not([class]) {
            color: $c-black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

}

@mixin light-grey-hover {
    &:hover {
        background-color: color.adjust($c-grey--light, $lightness: -10%);
    }
}

// Grey - dark
@mixin dark-grey {
    background-color: $c-grey--dark;
    color: $c-white;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-white;
    }

    a {
        &:not([class]) {
            color: $c-white;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@mixin dark-grey-hover {
    &:hover {
        background-color: color.adjust($c-grey--dark, $lightness: -10%);
    }
}

// White
@mixin white {
    background-color: $c-white;
    color: $c-black;

    .icon:not(.tag__svg, .btn__icon),
    .logo {
        fill: $c-grey--dark;
    }

    a {
        &:not([class]) {
            color: $c-black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@mixin white-hover {
    &:hover {
        background-color: color.adjust($c-white, $lightness: -10%);
    }
}
