.actions-bar {
    &__list {
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0 0;
    }

    &__item {
        flex: 0 1 auto;
        margin-right: 1.3rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
