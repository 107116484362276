@use 'sass:color';
@use '../config' as *;
@use '../mixins/clear-fix';
@use '../mixins/list-reset';
@use '../mixins/screen';
@use '../mixins/theme';
@use '../mixins/typography';
@use '../mixins/property-override';

.nav {
    $self: &;

    &__list {
        @include list-reset.apply;

        position: relative;
    }

    &__dropdown-icon {
        height: 16px;
        transform: rotate(0deg);
        transition: transform .25s ease;
        width: 20px;

        &.is-open {
            transform: rotate(180deg);
            transition: transform .25s ease;
        }
    }

    &__item {
        @include typography.heading;

        @include property-override.apply {
            margin-left: .2rem;
            margin-right: 1rem;
            min-width: 0;
            position: relative;
            text-transform: uppercase;
        }

        @include screen.apply(1230px) {
            margin-right: 1rem;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__link {
        color: inherit;
        display: inline-block;
        transition: .2s ease;
    }

    &__children {
        background-color: $c-grey--light;
        border-top: 3px solid $c-blue;
        display: none;
        height: 0;
        left: 0;
        max-height: 0;
        min-width: 100%;
        overflow: hidden;
        transition: max-height 250ms;
        z-index: 1;

        &.is-active {
            display: block;
            height: auto;
        }

        &.is-open {
            max-height: 300px;
        }
    }

    &__image {
        display: none;
    }

    &--main {
        #{$self}__list {
            @include theme.light-grey;

            font-size: 1rem;
            padding: 10px $p-container;
            width: 100%;
            z-index: 2;
        }

        // align on the height of the header
        #{$self}__item {
            border-bottom: 1px solid color.adjust($c-grey--dark, $alpha: -0.7);
            color: $c-grey--dark;
            display: block;
            line-height: $h-nav--full;
            margin: 0;
            padding-left: 1.25rem;

            &::before {
                background-color: $c-grey--dark;
                content: '';
                display: block;
                font-size: 1em;
                height: 10px;
                left: 0;
                position: absolute;
                top: 14px;
                transition: transform .3s cubic-bezier(0, 0, .2, 1);
                width: 10px;
            }

            &--child,
            &:last-child {
                border-bottom: 0;
            }

            &.is-active {
                color: $c-blue;
            }
        }

        #{$self}__link {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0.125rem 0;
        }
    }

    &--secondary {
        #{$self}__list {
            display: flex;
            gap: 0.5rem;
        }

        #{$self}__item {
            cursor: pointer;
            font-size: 1.25rem;
            height: $h-nav;
            line-height: $h-nav;
            margin: 0;
            overflow: hidden;
            top: 0;
            width: $h-nav;
        }

        #{$self}__link {
            color: $c-white;
            display: block;
            height: 100%;
            text-align: center;
            width: 100%;
        }

        #{$self}__icon {
            height: $h-icon;
            width: $h-icon;
        }
    }

    &--filter {
        #{$self}__item {
            line-height: $h-header;

            &:first-of-type {
                color: $c-black;
            }
        }

        #{$self}__children {
            width: 300px;

            #{$self}__item {
                color: $c-white;
                display: block;
            }
        }
    }

    @include screen.apply($screen-normal) {
        &__dropdown-icon {
            display: none;
        }

        &__item {
            &--child {
                color: $c-grey--dark;
            }
        }

        &__children {
            border: 0;
            display: none;
            max-width: none;
            position: absolute;
            top: $h-header;
            width: $w-container;

            &.is-active {
                display: flex;
                height: auto;
            }

            &.is-open {
                max-height: 600px;
            }
        }

        &__children-list {
            flex: 0 0 50%;
            padding: 2rem;
        }

        &__image {
            display: block;
            flex: 0 0 50%;
            padding: 2rem;
        }

        &--main {
            #{$self}__list {
                background: transparent;
                display: flex;
                gap: $p-container;
                padding: 0;
                position: initial;
            }

            #{$self}__item {
                border-bottom: 0;
                color: $c-blue;
                font-size: 1.125rem;
                padding-left: 0;
                position: initial;

                &::before {
                    display: none;
                }

                &--child {
                    color: $c-grey--dark;
                }
            }

            #{$self}__link {
                text-decoration: underline;
                text-decoration-color: transparent;
                transition:
                    color $transition-speed-fast $transition-ease,
                    text-decoration $transition-speed-fast $transition-ease;
                white-space: nowrap;

                &:focus,
                &:hover {
                    color: $c-grey--dark;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }

        &--secondary {
            #{$self}__item:first-of-type {
                display: none;
            }
        }
    }

    @include screen.apply($screen-large) {
        &__item {
            margin-right: 1rem;
        }
    }
}


