@use '../config' as *;
@use 'screen';

@mixin apply {
    margin: 0 auto;
    width: 100%;

    @include screen.apply($screen-simple) {
        width: $w-container-sm;
    }

    @include screen.apply($screen-normal) {
        width: $w-container;
    }
}
