@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';

.footer {
    position: relative;

    @include screen.apply($screen-normal) {
        padding-bottom: 80px;
    }

    p {
        line-height: 1.35;
        margin: 0;
    }

    &__buttons {
        display: flex;

        .footer__text {
            padding-right: 1rem;
        }
    }

    &__container {
        padding: 2rem 1.5rem;
    }

    &__list {
        @include screen.apply($screen-simple) {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            margin: 0 -2.5%;
        }

    }

    &__item {
        font-size: 1rem;
        margin: 0 0 3rem;

        @include screen.apply($screen-simple) {
            margin: 0 2.5% 3rem;
            width: 45%;

            &--wide {
                width: 80%;
            }
        }

        @include screen.apply($screen-normal) {
            width: 20%;

            &--wide {
                width: 40%;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__header {
        margin: 0 0 2rem;
    }

    &__text {
        margin: 0 0 1rem;

        &:last-of-type {
            margin: 0;
        }

        span {
            color: $c-blue;
        }

        &--bold {
            font-weight: typography.$weight-bold;
        }
    }

    &__link {
        color: $c-blue--light;

        &:hover {
            color: $c-blue--lightest;
        }
    }

    // theme
    &.theme--darkgrey {
        &:hover {
            background-color: $c-grey--dark;
        }
    }
}
