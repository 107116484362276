@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';


.searchbar {
    $self: &;

    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    &.is-hidden {
        display: none;
    }

    &__label {
        @include typography.heading;

        @include property-override.apply {
            color: $c-grey--dark;
            font-size: 2rem;
            margin-bottom: 0.5rem;
        }
    }

    &__field {
        position: relative;
        width: 100%;
    }

    &__input {
        background: $c-white;

        &[type='search'] {
            border: 0;
            border-radius: 2px;
            color: $c-black;
            font-size: 1.25rem;
            height: $h-input-text;
            line-height: $h-input-text;
            padding: 0 3rem 0 1rem;
            width: 100%;
        }
    }

    &__btn {
        height: calc($h-button - (2 * 2px));
        position: absolute;
        right: 2px;
        text-align: center;
        top: 2px;
        width: $h-button;

        .icon {
            fill: $c-grey--dark !important;
            height: 20px;
            width: 20px;
        }
    }

    &.theme--white {
        background: transparent;

        #{ $self }__btn {
            background: $c-white;

            .icon {
                fill: $c-blue !important;
            }
        }

        #{ $self }__input {
            border: 2px solid $c-blue;
        }
    }
}
