@use 'sass:color';
@use '../config' as *;
@use '../mixins/typography';
@use '../mixins/list-reset';
@use '../mixins/theme';
@use '../mixins/property-override';

* {
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    min-height: 100%;
    min-width: 320px;
}

body {
    @include typography.regular;

    @include property-override.apply {
        background-color: $c-white;
        color: $c-black;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include typography.heading;

    @include property-override.apply {
        margin: 0;
    }
}

h1 {
    @include typography.heading-1;

    &:not([class]) {
        margin-bottom: 1rem;
    }
}

h2 {
    @include typography.heading-2;

    &:not([class]) {
        margin: 1.4rem 0 .6rem;
    }
}

h3 {
    @include typography.heading-3;

    &:not([class]) {
        margin: 1.4rem 0 .6rem;
    }
}

h4 {
    @include typography.heading-4;

    &:not([class]) {
        margin: .5rem 0 .6rem;
    }
}

h5 {
    @include typography.heading-5;

    &:not([class]) {
        margin: .5rem 0 .6rem;
    }
}

hr {
    background-color: $c-grey--light;
    border: 0;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
}

a {
    cursor: pointer;
    text-decoration: none;

    &:not([class]) {
        color: $c-blue;
        cursor: pointer;
        transition: color .2s ease-in-out;

        &:hover {
            color: $c-blue--light;
        }
    }
}

p {
    &:not([class]) {
        margin: 0 0 1rem;

        &:last-of-type {
            margin: 0;
        }
    }
}

strong {
    font-weight: typography.$weight-bold;
}

dl {
    margin: 0 0 1rem;
}

dt {
    @include typography.heading;

    @include property-override.apply {
        font-weight: typography.$weight-bold;
        margin: .75rem 0 0;
    }
}

dd {
    @include typography.regular;
}

ul,
ol {
    @include list-reset.apply;

    margin: 0;
    padding: 0;

    &:not([class]) {
        margin: 0 0 2rem;

        li {
            margin-bottom: .25rem;
            padding-left: 1.25rem;
            position: relative;

            &::before {
                content: '';
                display: block;
                font-size: 1em;
                left: 0;
                position: absolute;
            }

            &:last-of-type {
                margin: 0;
            }
        }

        ul,
        ol {
            margin: .25rem 0 0;
        }
    }
}

ul {
    &:not([class]) {
        li {
            &::before {
                background: $c-black;
                height: 6px;
                top: 8px;
                width: 6px;

                .theme--blue & {
                    background: $c-white;
                }

                .theme--light-blue & {
                    background: $c-black;
                }

                .theme--lightest-blue & {
                    background: $c-black;
                }

                .theme--grey & {
                    background: $c-black;
                }

                .theme--light-grey & {
                    background: $c-black;
                }

                .theme--dark-grey & {
                    background: $c-white;
                }

                .theme--white & {
                    background: $c-black;
                }
            }
        }
    }
}

ol {
    &:not([class]) {
        counter-reset: ol-number;

        li {
            counter-increment: ol-number;

            &::before {
                content: counter(ol-number) '.';
                font-size: 1em;
                top: auto;
            }
        }
    }
}

blockquote {
    display: block;
    margin: 2rem 0;
    text-align: center;

    & > p {
        @include typography.quote;

        @include property-override.apply {
            color: $c-blue;
            display: inline-block;
            margin: 0;
        }

        &::before {
            content: '\201C';
            margin-right: .2rem;
        }

        &::after {
            content: '\201D';
            margin-left: .2rem;
        }
    }
}

figure {
    margin-bottom: 1.5rem;
}

figcaption {
    @include typography.regular;

    @include property-override.apply {
        font-size: .85rem;
        font-style: italic;
        padding: .25rem;
    }
}

abbr {
    &[title] {
        border-bottom: 1px dotted color.adjust($c-blue, $alpha: -.8);
        color: $c-blue;
        cursor: help;
        font-weight: typography.$weight-bold;
        text-decoration: none;
        transition: color .2s ease-in-out, border-color .2s ease-in-out;

        &:hover {
            border-color: $c-blue;
        }
    }
}

legend {
    font-size: 1.5rem;
    text-transform: uppercase;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

label,
input,
textarea,
select {
    @include typography.regular;

    &:focus {
        outline: none;
    }
}

textarea {
    resize: vertical;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 1rem;
    text-align: left;
    width: 100%;
}

th,
td {
    border: 1px solid $c-grey--light;
    height: 30px;
    padding: .5em .75em;
}

th {
    color: $c-blue;
    font-weight: typography.$weight-bold;
}

mark,
ins {
    background: $c-yellow;
    border: 0;
    color: inherit;
    text-decoration: none;
}

code,
kbd,
tt,
var,
samp,
pre {
    @include typography.code;
}

code {
    background: $c-grey--lightest;
    display: block;
    font-weight: typography.$weight-normal;
    padding: 1rem;
}

pre {
    border: 1px solid $c-blue;
    font-weight: typography.$weight-normal;
    margin-bottom: 1rem;
    max-width: 100%;
    overflow: auto;
    padding: .75rem;
    white-space: pre-wrap;
    word-wrap: break-word;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

.icon {
    height: $w-logo;
    width: $w-logo;
}

.logo {
    width: $w-logo;
}

.theme {
    &--blue {
        @include theme.blue;

        &.theme--hover {
            @include theme.blue-hover;
        }
    }

    &--light-blue {
        @include theme.light-blue;

        &.theme--hover {
            @include theme.light-blue-hover;
        }
    }

    &--lightest-blue {
        @include theme.lightest-blue;

        &.theme--hover {
            @include theme.lightest-blue-hover;
        }
    }

    &--grey {
        @include theme.grey;

        &.theme--hover {
            @include theme.grey-hover;
        }
    }

    &--light-grey {
        @include theme.light-grey;

        &.theme--hover {
            @include theme.light-grey-hover;
        }
    }

    &--dark-grey {
        @include theme.dark-grey;

        &.theme--hover {
            @include theme.dark-grey-hover;
        }
    }

    &--white {
        @include theme.white;

        &.theme--hover {
            @include theme.white-hover;
        }
    }

}
