@use '../config' as *;
@use '../mixins/typography';

.list {
    @include typography.list;

    &__item {
        padding-left: 1.25rem;
        position: relative;
        text-transform: uppercase;

        &::before {
            background-color: $c-black;
            content: '';
            display: block;
            font-size: 1em;
            height: 14px;
            left: 0;
            position: absolute;
            top: 5px;
            width: 14px;
        }
    }

    &.theme--blue {
        background: transparent;
        color: $c-blue;

        .list__item {
            &::before {
                background-color: $c-blue;
            }
        }
    }
}
