@use 'sass:color';
@use '../config' as *;
@use '../mixins/center';
@use '../mixins/object-fit';
@use '../mixins/screen';
@use '../mixins/sr-only';
@use '../mixins/stretch';
@use '../mixins/typography';

.mediablock {
    $self: &;

    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    &__image {
        @include stretch.apply;

        display: block;
        margin: 0;

        img {
            @include object-fit.apply;

            display: block;
        }
    }

    &__content {
        @include stretch.apply;

        bottom: 0;
        color: $c-white;
        height: auto;
        overflow: hidden;
        padding: 1.75rem 1.5rem;
    }

    &__heading {
        margin: 0 0 1rem;
        position: relative;
    }

    &__title {
        @include typography.heading-block;
    }

    &__subtitle {
        font-size: 0.9rem;
        margin-bottom: 0.3rem;
    }

    &__button {
        align-items: center;
        border-radius: 0;
        bottom: 0;
        display: flex;
        gap: 6px;
        justify-content: center;
        position: absolute;
        right: 0;
    }

    &__link {
        @include stretch.apply;

        span {
            @include sr-only.apply;
        }
    }

    &__play {
        @include center.both;

        fill: $c-white;
        transform-origin: center;
        transition: width 0.2s;
        width: 70px;
    }

    &__icon {
        fill: $c-white;
        height: auto;
        width: 100%;
    }

    &--image,
    &--video {
        background-color: $c-blue;

        #{ $self }__content {
            height: auto;

            &::before {
                @include stretch.apply;

                background: linear-gradient(to bottom, color.adjust($c-black, $alpha: -0.6) 75%, transparent);
                content: '';
                transform-origin: top center;
                transition:
                    background 0.2s ease-in,
                    translate 0.2s ease-in;
                transition-behavior: allow-discrete;
                translate: 0 -50%;
            }
        }
    }

    &--video {
        cursor: pointer;
    }

    &--link {
        &:focus,
        &:hover {
            #{ $self }__play {
                width: 90px;
            }

            #{ $self }__button {
                .btn {
                    &__icon {
                        animation: 0.5s bounce;
                    }
                }
            }
        }

        &#{ $self }--image {
            &:focus,
            &:hover {
                #{ $self }__content {
                    &::before {
                        background: linear-gradient(to bottom, color.adjust($c-black, $alpha: -0.6) 100%, transparent);
                        translate: 0 0;
                    }
                }
            }
        }
    }

    @include screen.apply($screen-simple) {
        &__play {
            width: 100px;
        }

        &--link {
            &:hover {
                #{ $self }__play {
                    width: 125px;
                }
            }
        }
    }
}

@keyframes bounce {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(6px);
    }

    100% {
        transform: translateX(0);
    }
}
