@use 'sass:color';
@use 'sass:math';
@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.block-overlay {
    background: color.adjust($c-black, $alpha: -.5);
    inset: 0;
    opacity: 0;
    padding: 1rem;
    position: fixed;
    transition: opacity .2s;
    width: 100%;
    z-index: 1000;

    &.is-hidden {
        display: none;
    }

    &.is-active {
        opacity: 1;

    }

    &__inner {
        background-color: $c-blue;
        margin: 0 auto;
        max-height: 100%;
        max-width: 480px;
        overflow: auto;
        position: relative;
    }

    &__content {
        color: $c-white;
        padding: 1.75rem 1.5rem;
    }

    &__close {
        background: color.adjust($c-black, $alpha: -.7);
        line-height: 1;
        padding: .5rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        &:hover {
            background: color.adjust($c-black, $alpha: -.5);
        }
    }

    &__icon {
        fill: $c-white;
        height: 16px;
        width: 16px;
    }

    &__header {
        left: 0;
        padding: 0 1.5rem .5rem;
        position: absolute;
        top: 250px;
        transform: translateY(-100%);
        z-index: 1;
    }

    &__heading {
        @include typography.heading-block;

        @include property-override.apply {
            margin-bottom: 1rem;
        }
    }

    &__subheading {
        font-size: 1rem;
        margin-bottom: .2rem;
    }

    &__text {
        max-height: 400px;
        overflow: hidden;

        a {
            color: $c-white;
            text-decoration: underline;

            &:hover {
                color: $c-white;
                text-decoration: none;
            }
        }
    }

    &__media {
        height: 250px;
        overflow: hidden;
        position: relative;

        &::after {
            background: color.adjust($c-black, $alpha: -.5);
            content: '';
            inset: 0;
            position: absolute;
        }
    }

    &__picture,
    &__image {
        display: block;
        height: 100%;
        max-width: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
    }

    @include screen.apply($screen-simple) {
        background: none;
        bottom: auto;
        padding: 0;
        position: absolute;
        z-index: auto;

        &__inner {
            display: flex;
            height: $w-container-sm * 0.5;
            max-width: none;
        }

        &__header {
            left: auto;
            padding: 0;
            position: relative;
            top: auto;
            transform: none;
            z-index: auto;
        }

        &__content {
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            max-height: 100%;
            overflow: hidden;
            width: $w-container-sm * 0.5;
        }

        &__text {
            max-height: none;
            overflow: hidden;
        }

        &__media {
            flex: 0 0 auto;
            order: 1;
            padding-bottom: $w-container-sm * 0.5;
            width: $w-container-sm * 0.5;

            &::after {
                display: none;
            }
        }
    }

    @include screen.apply($screen-normal) {
        &__inner {
            height: math.div($w-container, 3);
        }

        &__subheading {
            font-size: 1.2rem;
        }

        &__content {
            width: math.div($w-container, 3) * 2;
        }

        &__media {
            flex: 0 0 auto;
            padding-bottom: math.div($w-container, 3);
            width: math.div($w-container, 3);
        }
    }
}
