@mixin apply($min-size: 0, $max-size: 0) {
    @if $min-size and $max-size == 0 {
        @media only screen and (min-width: $min-size) {
            @content;
        }
    } @else if $min-size and $max-size {
        @media only screen and (min-width: $min-size) and (max-width: $max-size) {
            @content;
        }
    } @else if $min-size == 0 and $max-size {
        @media only screen and (max-width: $max-size) {
            @content;
        }
    }
}
