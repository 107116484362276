@use 'sass:math';
@use '../config' as *;
@use '../mixins/animations';
@use '../mixins/clear-fix';
@use '../mixins/screen';
@use '../mixins/typography';

.overview {
    $self: &;

    position: relative;

    &__header {
        margin: 4rem $p-container .7rem;

        @include screen.apply($screen-simple) {
            margin: 4rem 0 .7rem;
        }
    }

    &__heading {
        @include typography.heading-1;
    }

    &__content {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__filter {
        height: $h-header;
        line-height: $h-header;
        padding: 0 1.5rem;

        @include screen.apply($screen-normal) {
            height: $h-header;
            line-height: $h-header;
        }
    }

    &__list {
        @include clear-fix.apply;
    }

    &__item {
        float: left;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        width: 100%;

        // Tablet screen
        @include screen.apply($screen-minimal, $screen-normal) {
            padding-bottom: 50%;
            width: 50%;
        }

        // Desktop screen
        @include screen.apply($screen-normal) {
            padding-bottom: 50%;
            width: 50%;
        }

        &--empty {
            display: none;
        }

        // vertical blocks
        @include screen.apply($screen-minimal, $screen-normal) {
            &--empty {
                display: block;

                &:nth-of-type(3n) {
                    .overview--square & {
                        display: none;
                    }
                }

                &:nth-of-type(9n+6) {
                    display: none;

                    .overview--square & {
                        display: block;
                    }
                }
            }

            &:nth-of-type(9n+3) {
                padding-bottom: 100%;

                .overview--square & {
                    padding-bottom: 50%;
                }
            }
        }


        &.cols-3 {
            // Desktop screen
            @include screen.apply($screen-normal) {
                padding-bottom: math.div($w-container, 3);
                width: math.div($w-container, 3);

                &--empty {
                    display: block;
                }

                // horizontal blocks
                &:nth-of-type(9n+2),
                &:nth-of-type(9n+3) {
                    width: math.div($w-container, 3) * 2;

                    .overview--square & {
                        width: math.div($w-container, 3);
                    }
                }

                // vertical blocks
                &:nth-of-type(9n+3) {
                    float: right;
                    padding-bottom: math.div($w-container, 3) * 2;
                    width: math.div($w-container, 3);

                    .overview--square & {
                        padding-bottom: math.div($w-container, 3);
                    }
                }
            }
        }

        &.cols-4 {
            // Desktop screen
            @include screen.apply($screen-normal) {
                padding-bottom: $w-container * 0.25;
                width: $w-container * 0.25;

                &--empty {
                    display: block;
                }

                // horizontal blocks
                &:nth-of-type(9n+2),
                &:nth-of-type(9n+4) {
                    width: $w-container * 0.25 * 2;

                    .overview--square & {
                        width: $w-container * 0.25;
                    }
                }

                // vertical blocks
                &:nth-of-type(9n+4) {
                    float: right;
                    padding-bottom: $w-container * 0.25 * 2;
                    width: $w-container * 0.25;

                    .overview--square & {
                        padding-bottom: $w-container * 0.25;
                    }
                }
            }
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        &__item {
            opacity: 0;
        }

        &.in-view {
            --vertical-animation-translate: 10%;

            #{ $self }__item {
                @include animations.fade-in-vertical('to-top', $transition-speed-slow);
            }

            @for $i from 1 through 100 {
                #{ $self }__item {
                    &:nth-child(#{ $i }) {
                        animation-delay: ($i - 1) * .15s;
                    }
                }
            }
        }
    }
}
