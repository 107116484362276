@use '../config' as *;
@use '../mixins/screen';

.section {
    padding: $p-block--small;

    &__button {
        display: inline-block;
        margin-bottom: .5rem;
    }

    &__color {
        line-height: 50px;
        margin-bottom: .5rem;

        span {
            float: right;
        }

        &::before {
            content: '';
            float: left;
            height: 50px;
            line-height: 50px;
            margin-right: 20px;
            width: 50px;
        }

        &--blue {
            &::before {
                background: $c-blue;
            }
        }

        &--lightblue {
            &::before {
                background: $c-blue--light;
            }
        }

        &--lightestblue {
            &::before {
                background: $c-blue--lightest;
            }
        }

        &--grey {
            &::before {
                background: $c-grey;
            }
        }

        &--lightgrey {
            &::before {
                background: $c-grey--light;
            }
        }

        &--darkgrey {
            &::before {
                background: $c-grey--dark;
            }
        }

        &--black {
            &::before {
                background: $c-black;
            }
        }

        &--white {
            &::before {
                background: $c-white;
                border: solid 1px $c-grey;
            }
        }
    }

    &__list {
        @include screen.apply($screen-simple) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }

    &__item {
        margin-bottom: 5rem;

        @include screen.apply($screen-simple) {
            width: 45%;
        }

        &:last-of-type {
            margin: 0;
        }

        &--full {
            width: 100%;
        }
    }

    &__header {
        margin-bottom: 1rem;
    }

    &__heading {
        color: $c-blue;
        margin-bottom: 1rem;
    }

    &__block {
        margin-bottom: 1rem;

        &:last-of-type {
            margin: 0;
        }
    }
}
