@use '../config' as *;
@use '../mixins/list-reset';
@use '../mixins/typography';
@use '../mixins/property-override';

.downloads {
    background: $c-blue;
    height: auto;
    padding: 1rem $p-container;
    width: 100%;

    & > h3 {
        @include typography.regular;

        @include property-override.apply {
            color: $c-white;
            font-size: 1.525rem;
            font-weight: typography.$weight-bold;
            margin: 10px 0 20px;
        }
    }

    &__list {
        @include list-reset.apply;
    }

    &__item {
        margin-bottom: .4rem;
    }

    &__link {
        align-items: center;
        color: $c-white;
        display: flex;
        transition: color .2s;

        &:hover {
            color: $c-black;

            .downloads__icon {
                background-color: $c-black;
            }

            .downloads__svg {
                fill: $c-white;
            }
        }
    }

    &__icon {
        background-color: $c-white;
        display: inline-block;
        flex-shrink: 0;
        height: 24px;
        position: relative;
        transition: background-color .2s;
        width: 24px;
    }

    &__svg {
        fill: $c-blue;
        height: 14px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: fill .2s;
        width: 14px;
    }

    &__title {
        @include typography.heading;

        @include property-override.apply {
            font-size: .8rem;
            margin-left: .5rem;
        }
    }
}
