@use 'sass:color';
@use '../config' as *;
@use '../mixins/sr-only';
@use '../mixins/stretch';
@use '../mixins/typography';
@use '../mixins/theme';
@use '../mixins/property-override';

.block {
    $self: &;

    color: inherit;
    height: 100%;
    position: relative;
    width: 100%;

    &:hover {
        .block__image {
            filter: grayscale(0);
        }

        .block__overlay {
            background-color: transparent;
        }
    }

    &__btn {
        color: inherit;
        position: relative;

        &::before {
            background-color: inherit;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1.75rem 1.5rem;
        position: relative;
        width: 100%;
    }

    &__media {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__overlay {
        background-color: color.adjust($c-black, $alpha: -.4);
        inset: 0;
        position: absolute;
        transition: background-color .2s;

        &--blue {
            background-color: color.adjust($c-blue, $alpha: -.5);
        }

        &--grey {
            background-color: color.adjust($c-black, $alpha: -.4);
        }

        &--darkgrey {
            background-color: color.adjust($c-black, $alpha: -.3);
        }

        &--lightgrey {
            background-color: color.adjust($c-grey--light, $alpha: -.5);

        }
    }

    &__image {
        display: block;
        margin: auto;
        transition: filter .2s;
        width: 100%;
    }

    &__header {
        position: relative;
    }

    &__heading {
        @include typography.heading-block;

        @include property-override.apply {
            margin-bottom: .4rem;
        }
    }

    &__subheading {
        font-size: inherit;
    }

    &__footer {
        bottom: 0;
        padding: 1.75rem 0;
        position: absolute;
        width: 100%;
    }

    &__link {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        span {
            @include sr-only.apply;
        }
    }

    &__text {
        margin-bottom: 1.5rem;
        max-height: 10em;
        overflow: hidden;
        padding: 0 1.5rem;
        position: relative;

        &::before {
            bottom: 0;
            content: '...';
            padding: 0 1em;
            position: absolute;
            right: 1.6rem;
        }

        &::after {
            content: '';
            height: 1em;
            margin-top: -1em;
            position: absolute;
            right: 1.5rem;
            width: 5em;
        }
    }

    &__category {
        bottom: 1.5rem;
        font-size: .9rem;
        font-weight: typography.$weight-bold;
        line-height: 1;
        padding: 0 0 0 1.4rem;
        position: absolute;
        text-transform: uppercase;

        &::before {
            content: '';
            display: block;
            height: 12px;
            left: 0;
            position: absolute;
            top: 1px;
            width: 12px;

            .theme--blue & {
                background-color: $c-white;
            }

            .theme--light-blue & {
                background-color: $c-black;
            }

            .theme--lightest-blue & {
                background-color: $c-black;
            }

            .theme--grey & {
                background-color: $c-black;
            }

            .theme--light-grey & {
                background-color: $c-black;
            }

            .theme--dark-grey & {
                background-color: $c-white;
            }

            .theme--white & {
                background-color: $c-white;
            }
        }
    }

    &__tags {
        bottom: 1.5rem;
        display: flex;
        flex-flow: row-reverse wrap;
        gap: .2rem;
        left: 1.5rem;
        margin: auto -.2rem 0;
    }

    &__tag {
        background-color: $c-white;
        padding: .4rem;
    }

    .event-info {
        color: $c-blue;
        margin: 0;
    }

    .theme--blue & {
        .event-info {
            color: $c-white;
        }

        .block__text {
            &::after {
                @include theme.blue;
            }
        }

        .block__btn {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-blue, $lightness: 10%);
                }
            }
        }
    }

    .theme--light-blue & {
        .event-info {
            color: $c-white;
        }

        .block__text {
            &::after {
                @include theme.light-blue;
            }
        }

        .block__btn {
            color: $c-black;

            &::before {
                background-color: $c-black;
            }

            &::after {
                border-color: $c-black;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-blue--light, $lightness: -10%);
                }
            }
        }
    }

    .theme--lightest-blue & {
        .event-info {
            color: $c-black;
        }

        .block__text {
            &::after {
                @include theme.lightest-blue;
            }
        }

        .block__btn {
            color: $c-black;

            &::before {
                background-color: $c-black;
            }

            &::after {
                border-color: $c-black;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-blue--lightest, $lightness: -10%);
                }
            }
        }
    }

    .theme--grey & {
        .event-info {
            color: $c-white;
        }

        .block__text {
            &::after {
                @include theme.grey;
            }
        }

        .block__btn {
            color: $c-black;

            &::before {
                background-color: $c-black;
            }

            &::after {
                border-color: $c-black;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-grey, $lightness: -10%);
                }
            }
        }
    }

    .theme--light-grey & {
        .event-info {
            color: $c-black;
        }

        .block__text {
            &::after {
                @include theme.light-grey;
            }
        }

        .block__btn {
            color: $c-black;

            &::before {
                background-color: $c-black;
            }

            &::after {
                border-color: $c-black;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-grey--light, $lightness: -10%);
                }
            }
        }
    }

    .theme--dark-grey & {
        .event-info {
            color: $c-blue;
        }

        .block__text {
            &::after {
                @include theme.dark-grey;
            }
        }

        .block__btn {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-grey--dark, $lightness: -10%);
                }
            }
        }
    }

    .theme--white & {
        .event-info {
            color: $c-black;
        }

        .block__text {
            &::after {
                @include theme.white;
            }
        }

        .block__btn {
            color: $c-black;

            &::before {
                background-color: $c-black;
            }

            &::after {
                border-color: $c-black;
            }
        }

        &:hover {
            .block__text {
                &::after {
                    background-color: color.adjust($c-white, $lightness: -10%);
                }
            }
        }
    }

    &--img {
        color: $c-white;
        text-shadow: 0 0 30px $shadow-text;

        .event-info {
            color: $c-white;
        }

        .block__btn {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        #{ $self }__content {
            height: 100%;

            &::before {
                @include stretch.apply;

                background: linear-gradient(to bottom, color.adjust($c-black, $alpha: -0.6) 75%, transparent);
                content: '';
                transform-origin: top center;
                transition:
                    background 0.2s ease-in,
                    translate 0.2s ease-in;
                transition-behavior: allow-discrete;
                translate: 0 -50%;
            }
        }

        &:focus,
        &:hover {
            #{ $self }__content {
                &::before {
                    background: linear-gradient(to bottom, color.adjust($c-black, $alpha: -0.6) 100%, transparent);
                    translate: 0 0;
                }
            }
        }
    }

    &--filter {
        .block__image {
            filter: grayscale(1);
        }

        &:hover {
            .block__image {
                filter: grayscale(0);
            }
        }
    }

}
