@use 'sass:color';
@use '../config' as *;
@use '../mixins/typography';
@use '../mixins/screen';
@use '../mixins/stretch';
@use '../mixins/property-override';

@mixin cell-color($color) {
    background-color: $color;

    &:hover {
        background-color: color.adjust($color, $lightness: -15%);
    }
}

.schedule {
    border-left: 0;
    color: $c-black;
    font-size: .85em;
    font-weight: typography.$weight-bold;
    margin: 2rem auto 3rem;
    table-layout: fixed;
    width: 100%;

    &__wrapper {
        overflow-x: scroll;
        width: 100%;

        @include screen.apply($screen-normal) {
            overflow-x: visible;
        }
    }

    caption {
        @include typography.heading-1;

        @include property-override.apply {
            color: $c-blue;
            font-weight: typography.$weight-bold;
        }
    }

    &__column {
        width: 130px;

        &--time {
            width: 60px;
        }
    }

    &__head-cell {
        background-color: $c-black;
        color: $c-white;
        text-align: center;
        text-transform: uppercase;

        &--empty {
            background-color: $c-white;
        }
    }

    &__time {
        background-color: $c-white;
        color: $c-black;
        left: 0;
        position: sticky;
        vertical-align: top;
        z-index: 2;
    }

    &__break {
        font-size: .85em;
        text-transform: uppercase;
    }

    &__talk {
        @include cell-color($c-grey--lightest);

        display: table-cell;
        padding: 15px;
        position: relative;
        transition: background-color 100ms;
        vertical-align: top;

        &--color1 {
            @include cell-color($c-blue--light);
        }

        &--color2 {
            @include cell-color($c-blue--lightest);
        }

        &--color3 {
            @include cell-color($c-red--light);
        }

        &--color4 {
            @include cell-color($c-red--lightest);
        }

        &--color5 {
            @include cell-color($c-grey--light);
        }

        &--color6 {
            @include cell-color($c-yellow--light);
        }
    }

    &__link {
        @include stretch.apply;

        span {
            display: none;
        }
    }

    tr {
        th {
            border: 5px solid $c-white;
            border-left: 0;
            padding: 1em;
        }
    }

    td {
        border: 5px solid $c-white;
        padding: 1em;
    }
}

.legend {
    &__items {
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        @include typography.small;

        font-weight: typography.$weight-bold;
        margin: 5px 3px;
        padding: 10px 5px;
        text-align: center;
        width: 240px;

        &--color1 {
            background-color: $c-blue--light;
        }

        &--color2 {
            background-color: $c-blue--lightest;
        }

        &--color3 {
            background-color: $c-red--light;
        }

        &--color4 {
            background-color: $c-red--lightest;
        }

        &--color5 {
            background-color: $c-grey--light;
        }

        &--color6 {
            background-color: $c-yellow--light;
        }
    }
}
