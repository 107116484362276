@use '../config' as *;
@use '../mixins/typography';
@use '../mixins/property-override';

.content {
    &__heading {
        @include typography.heading-1;

        @include property-override.apply {
            margin: 0 0 .6rem;
        }
    }

    &__subheading {
        font-size: inherit;
        margin: 0;
    }

    &__container {
        max-width: 800px;
        padding: 3rem $p-container;
    }

    &__info {
        margin: 0 0 1rem;
    }
}
