@use '../config' as *;
@use '../mixins/object-fit';
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.people-item {
    $self: &;

    &:focus,
    &:hover {
        #{ $self }__image {
            &--default {
                opacity: 0;
            }

            &--no-hover {
                    opacity: 1;
            }
        }

        #{ $self }__text {
            &--hover {
                opacity: 1;
                transform: translateY(0)
            }
        }
    }

    &__media {
        aspect-ratio: 2/3;
        background-color: $c-grey;
        position: relative;
        width: 100%;
    }

    &__image {
        left: 0;
        position: absolute;
        transition: opacity 0.3s ease-in-out;
        width: 100%;

        img {
            @include object-fit.apply;
        }
    }

    &__content {
        height: 135px;
        overflow: hidden;
        position: relative;
    }

    &__text {
        background-color: $c-grey--dark;
        color: $c-white;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: absolute;
        width: 100%;

        &--hover {
            opacity: 0;
            transform: translateY(100%);
            transition: transform 0.3s, opacity 0.3s;;
        }
    }

    &__quote {
        &::after,
        &::before {
            content: "\0022	";
        }
    }

    &__title {
        @include typography.heading-4;

        @include property-override.apply {
            margin-bottom: 0.5rem;
        }
    }

    &--blue {
        #{ $self }__text {
            background-color: $c-blue;
        }
    }

    &--light-blue {
        #{ $self }__text {
            background-color: $c-blue--light;
            color: $c-grey--dark;
        }
    }

    &--white {
        #{ $self }__text {
            background-color: $c-white;
            color: $c-grey--dark;
        }
    }

    @include screen.apply($screen-minimal) {
        &__content {
            height: 175px;
        }
    }

    @include screen.apply($screen-normal) {
        &__content {
            height: 165px;
        }
    }

}
