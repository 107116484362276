@mixin both {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin vertical {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

@mixin horizontal {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
}
