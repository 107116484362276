@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.filters {
    &__select {
        position: relative;
    }

    &__bar {
        background-color: $c-grey;
        display: flex;
        justify-content: space-between;
        padding-left: 1.5rem;
    }

    &__title {
        @include typography.heading;

        @include property-override.apply {
            color: $c-white;
            display: inline-block;
            font-size: 1rem;
            padding: 1rem 0;
        }
    }

    &__toggle {
        @include typography.heading;

        @include property-override.apply {
            background: $c-grey--medium;
            border: 0;
            color: $c-white;
            font-size: 1rem;
            outline: 0;
            padding: 1rem 1.5rem;
            transition: background .2s;
        }

        &:hover {
            background: $c-grey--dark;
        }

        &.is-active {
            background: $c-blue;
        }
    }

    &__toggle-icon {
        fill: $c-white;
        height: 16px;
        margin-right: .2rem;
        vertical-align: -2px;
        width: 16px;
    }

    &__options {
        background: $c-blue;
        max-height: 0;
        overflow: hidden;
        padding: 0 1.5rem;
        position: relative;
        transition: max-height .2s, padding .2s;
        width: 100%;
        z-index: 1;

        &::after {
            background-color: transparent;
            content: '';
            height: 2px;
            left: 0;
            margin-top: -2px;
            position: absolute;
            right: 0;
            top: 100%;
            transition: background-color .2s .1s;
        }

        &.is-active {
            max-height: fit-content;
            padding: 1rem 1.5rem;

            &::after {
                background-color: $c-white;
            }
        }
    }

    &__options-section {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__options-title {
        @include typography.heading;

        @include property-override.apply {
            color: $c-black;
            margin-bottom: .3rem;
        }
    }

    &__selected {
        background: $c-blue;
        padding: 1rem 1.5rem;

        &.is-hidden {
            display: none;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -.3rem;
    }

    &__item {
        padding: .3rem;
    }

    @include screen.apply($screen-minimal) {
        &__title,
        &__toggle,
        &__options-title {
            font-size: 1.1rem;
        }
    }
}
