@use 'sass:color';
@use '../config' as *;
@use '../mixins/list-reset';
@use '../mixins/typography';

.tags {
    margin-bottom: 1rem;
    max-width: 400px;

    &__title {
        @include typography.small;

        font-weight: typography.$weight-bold;
        margin-bottom: .5rem;

        &--hide {
            visibility: hidden;
        }
    }

    &__list {
        @include list-reset.apply;

        display: flex;
        flex-wrap: wrap;
        margin: -2px;
    }

    &__item {
        @include typography.small;

        font-weight: typography.$weight-bold;
        margin: 2px;
        text-transform: uppercase;
        vertical-align: top;
    }

    &__link {
        display: block;
        max-width: none;
        padding: .25rem .5rem;
    }

    &.theme--white {
        background: transparent;

        .tags__title {
            color: $c-white;
        }

        .tags__item {
            background-color: $c-white;
            color: $c-blue;

            &:hover {
                background-color: color.adjust($c-white, $lightness: -10%);
            }
        }
    }

    &.theme--light-grey {
        background: transparent;

        .tags__item {
            background-color: $c-grey--light;
            color: $c-black;

            &:hover {
                background-color: color.adjust($c-grey--light, $lightness: -10%);
            }
        }
    }

}
