@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.highlighted-page {
    background-color: $c-grey--lightest;
    min-height: 300px;
    overflow: hidden;
    position: relative;

    @include screen.apply($screen-minimal) {
        min-height: 500px;
    }

    @include screen.apply($screen-simple) {
        min-height: 0;
    }

    &--small {
        min-height: calc((#{$screen-simple}) / 3);
        padding-bottom: 0;
    }

    &__heading {
        @include typography.heading-1;

        @include property-override.apply {
            margin-bottom: 1rem;
            padding: 0;
        }
    }

    &__subheading {
        @include typography.heading-1;

        @include property-override.apply {
            font-weight: bold;
            padding: 0;
            width: 80%;
        }
    }

    &__content {
        padding: 1.75rem $p-container 2.5rem;
        width: 100%;

        .highlighted-page--small & {
            height: auto;
            position: relative;
            top: auto;
        }

        @include screen.apply($screen-simple) {
            height: 100%;
        }
    }

    &__text {
        font-size: 1.1rem;
    }

    &__media {
        display: inline-block;
        height: 300px;
        margin: 0;
        max-height: 300px;
        overflow: hidden;
        position: relative;
        width: 100%;

        .media {
            position: absolute;
            top: -33%;
        }
    }

    &__image {
        display: block;
        left: 50%;
        margin: auto;
        max-width: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;

        &--video {
            height: 100%;
            width: auto;
        }

        @include screen.apply($screen-simple) {
            left: auto;
            position: static;
            top: auto;
            transform: translate(0, 0);
            width: 100%;

            &--video {
                height: auto;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }

        @include screen.apply($screen-normal) {
            display: block;
            left: 50%;
            margin: auto;
            max-width: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;

            &--video {
                height: auto;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }
    }

    &__link {
        margin-top: 20px;
    }

    &__icon {
        height: auto;
        width: 100%;
    }

    &__overlay {
        background: $c-overlay-image;
        inset: 0;
        position: absolute;
    }

    &.theme--blue {
        .banner__subheading {
            color: $c-black;
        }
    }

    &.theme--light-blue {
        .banner__subheading {
            color: $c-white;
        }
    }

    &--image {
        color: $c-white;
        text-shadow: 0 0 30px $shadow-text;

        @include screen.apply($screen-minimal) {
            min-height: 300px;
        }

        @include screen.apply($screen-simple) {
            min-height: 300px;
        }

        .banner__content {
            height: 100%;
            position: absolute;
            top: 0;
        }
    }

    &--hover {
        cursor: pointer;

        &:hover {
            .banner__image {
                transform: translate(-50%, -50%) scale(1.25);
                transition: transform 1.5s;
                transition-timing-function: ease-in-out;
            }
        }
    }
}
