@use '../config' as *;

.menu-icon {
    display: inline-block;
    height: 15px;
    position: relative;
    width: 18px;

    &__bar {
        background: $c-grey--dark;
        border-radius: 1px;
        display: block;
        height: 3px;
        position: absolute;
        width: 18px;

        &:nth-child(1) {
            top: 0;
            transition: transform 180ms;

            .is-open & {
                transform: translateY(6px) rotate(45deg);
                transition: transform 180ms 160ms;
            }
        }

        &:nth-child(2) {
            top: 6px;
            transition: width 180ms 180ms;

            .is-open & {
                transition: width 180ms;
                width: 0;
            }
        }

        &:nth-child(3) {
            bottom: 0;
            transition: transform 180ms;

            .is-open & {
                transform: translateY(-6px) rotate(-45deg);
                transition: transform 180ms 160ms;
            }
        }
    }
}
