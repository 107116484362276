@use '../config' as *;
@use '../mixins/screen';
@use '../mixins/property-override';

// Available font families included from fonts.com

// Font options
$weight-light: 300; // Helvetica Neue LT STD Thin
$weight-normal: 400; // Helvetica Neue LT STD Roman
$weight-bold: 700; // Helvetica Neue LT STD Condensed Heavy
$font: 'Neue Helvetica W01', 'Helvetica', 'sans-serif';

// Text
@mixin regular {
    font-family: $font;
    font-size: 1rem;
    font-weight: $weight-light;
    line-height: 1.4;

    @include screen.apply($screen-minimal) {
        letter-spacing: .5px;
    }

    @include screen.apply($screen-normal) {
        font-size: 1.05rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 1.125rem;
    }
}

@mixin quote {
    font-size: 1.5rem;
    font-weight: $weight-bold;
    line-height: 1.3;

    @include screen.apply($screen-normal) {
        font-size: 1.75rem;
    }
}

@mixin code {
    font-size: .825rem;
    line-height: 1.625;
}

@mixin small {
    font-size: .875rem;
    font-weight: $weight-normal;
    text-transform: uppercase;
}

@mixin list {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.625;
}

// Headings
@mixin heading {
    font-size: 1rem;
    font-weight: $weight-bold;
    letter-spacing: .5px;
    line-height: 1.15;
    text-transform: uppercase;

    @include screen.apply($screen-minimal) {
        letter-spacing: .75px;
    }
}

@mixin heading-1 {
    font-size: 2rem;

    @include screen.apply($screen-simple) {
        font-size: 2.25rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 2.5rem;
    }
}

@mixin heading-2 {
    font-size: 1.75rem;

    @include screen.apply($screen-simple) {
        font-size: 2rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 2.25rem;
    }
}

@mixin heading-3 {
    font-size: 1.5rem;

    @include screen.apply($screen-simple) {
        font-size: 1.75rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 2rem;
    }
}

@mixin heading-4 {
    @include property-override.apply {
        font-size: 1.25rem;
    }

    @include screen.apply($screen-simple) {
        font-size: 1.5rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 1.75rem;
    }
}

@mixin heading-5 {
    @include property-override.apply {
        font-size: 1rem;
    }

    @include screen.apply($screen-simple) {
        font-size: 1.25rem;
    }

    @include screen.apply($screen-normal) {
        font-size: 1.5rem;
    }
}

@mixin heading-block {
    font-size: 1.4rem;
    font-weight: $weight-bold;

    @include screen.apply($screen-simple) {
        font-size: 1.7rem;
    }
}

