@charset "UTF-8";
html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
}

* {
  box-sizing: initial;
}
*::after {
  box-sizing: inherit;
}
*::before {
  box-sizing: inherit;
}

body {
  font-family: sans-serif;
  line-height: 1;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  margin-bottom: 0;
  margin-top: 0;
}

dl {
  margin-bottom: 0;
  margin-top: 0;
}

dd {
  margin-left: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
}

strong {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

a {
  color: inherit;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Main color */
/**
    Temporary fix for https://github.com/stylelint/stylelint/issues/7893
    This is not needed anymore after this bug by Stylelint is fixed.

    What to do after fix:
    ```scss
    // replace i.e.
    @include property-override {
        margin-top: 0 0 1rem;
    }
    // with
    & {
        margin-top: 0 0 1rem;
    }
    ```
 */
* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
}

body {
  font-family: "Neue Helvetica W01", "Helvetica", "sans-serif";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width: 480px) {
  body {
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body {
    font-size: 1.05rem;
  }
}
@media only screen and (min-width: 1024px) {
  body {
    font-size: 1.125rem;
  }
}
body {
  background-color: #fff;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.75px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 2.5rem;
  }
}
h1:not([class]) {
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.75rem;
}
@media only screen and (min-width: 768px) {
  h2 {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  h2 {
    font-size: 2.25rem;
  }
}
h2:not([class]) {
  margin: 1.4rem 0 0.6rem;
}

h3 {
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) {
  h3 {
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  h3 {
    font-size: 2rem;
  }
}
h3:not([class]) {
  margin: 1.4rem 0 0.6rem;
}

h4 {
  font-size: 1.25rem;
}
@media only screen and (min-width: 768px) {
  h4 {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  h4 {
    font-size: 1.75rem;
  }
}
h4:not([class]) {
  margin: 0.5rem 0 0.6rem;
}

h5 {
  font-size: 1rem;
}
@media only screen and (min-width: 768px) {
  h5 {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  h5 {
    font-size: 1.5rem;
  }
}
h5:not([class]) {
  margin: 0.5rem 0 0.6rem;
}

hr {
  background-color: #e1e2e3;
  border: 0;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}
a:not([class]) {
  color: #009bd4;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
a:not([class]):hover {
  color: #8dd8f8;
}

p:not([class]) {
  margin: 0 0 1rem;
}
p:not([class]):last-of-type {
  margin: 0;
}

strong {
  font-weight: 700;
}

dl {
  margin: 0 0 1rem;
}

dt {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  dt {
    letter-spacing: 0.75px;
  }
}
dt {
  font-weight: 700;
  margin: 0.75rem 0 0;
}

dd {
  font-family: "Neue Helvetica W01", "Helvetica", "sans-serif";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width: 480px) {
  dd {
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  dd {
    font-size: 1.05rem;
  }
}
@media only screen and (min-width: 1024px) {
  dd {
    font-size: 1.125rem;
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}
ul:not([class]),
ol:not([class]) {
  margin: 0 0 2rem;
}
ul:not([class]) li,
ol:not([class]) li {
  margin-bottom: 0.25rem;
  padding-left: 1.25rem;
  position: relative;
}
ul:not([class]) li::before,
ol:not([class]) li::before {
  content: "";
  display: block;
  font-size: 1em;
  left: 0;
  position: absolute;
}
ul:not([class]) li:last-of-type,
ol:not([class]) li:last-of-type {
  margin: 0;
}
ul:not([class]) ul,
ul:not([class]) ol,
ol:not([class]) ul,
ol:not([class]) ol {
  margin: 0.25rem 0 0;
}

ul:not([class]) li::before {
  background: #000;
  height: 6px;
  top: 8px;
  width: 6px;
}
.theme--blue ul:not([class]) li::before {
  background: #fff;
}
.theme--light-blue ul:not([class]) li::before {
  background: #000;
}
.theme--lightest-blue ul:not([class]) li::before {
  background: #000;
}
.theme--grey ul:not([class]) li::before {
  background: #000;
}
.theme--light-grey ul:not([class]) li::before {
  background: #000;
}
.theme--dark-grey ul:not([class]) li::before {
  background: #fff;
}
.theme--white ul:not([class]) li::before {
  background: #000;
}

ol:not([class]) {
  counter-reset: ol-number;
}
ol:not([class]) li {
  counter-increment: ol-number;
}
ol:not([class]) li::before {
  content: counter(ol-number) ".";
  font-size: 1em;
  top: auto;
}

blockquote {
  display: block;
  margin: 2rem 0;
  text-align: center;
}
blockquote > p {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
}
@media only screen and (min-width: 1024px) {
  blockquote > p {
    font-size: 1.75rem;
  }
}
blockquote > p {
  color: #009bd4;
  display: inline-block;
  margin: 0;
}
blockquote > p::before {
  content: "“";
  margin-right: 0.2rem;
}
blockquote > p::after {
  content: "”";
  margin-left: 0.2rem;
}

figure {
  margin-bottom: 1.5rem;
}

figcaption {
  font-family: "Neue Helvetica W01", "Helvetica", "sans-serif";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width: 480px) {
  figcaption {
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  figcaption {
    font-size: 1.05rem;
  }
}
@media only screen and (min-width: 1024px) {
  figcaption {
    font-size: 1.125rem;
  }
}
figcaption {
  font-size: 0.85rem;
  font-style: italic;
  padding: 0.25rem;
}

abbr[title] {
  border-bottom: 1px dotted rgba(0, 155, 212, 0.2);
  color: #009bd4;
  cursor: help;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
abbr[title]:hover {
  border-color: #009bd4;
}

legend {
  font-size: 1.5rem;
  text-transform: uppercase;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label,
input,
textarea,
select {
  font-family: "Neue Helvetica W01", "Helvetica", "sans-serif";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width: 480px) {
  label,
  input,
  textarea,
  select {
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  label,
  input,
  textarea,
  select {
    font-size: 1.05rem;
  }
}
@media only screen and (min-width: 1024px) {
  label,
  input,
  textarea,
  select {
    font-size: 1.125rem;
  }
}
label:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 1rem;
  text-align: left;
  width: 100%;
}

th,
td {
  border: 1px solid #e1e2e3;
  height: 30px;
  padding: 0.5em 0.75em;
}

th {
  color: #009bd4;
  font-weight: 700;
}

mark,
ins {
  background: #ffc800;
  border: 0;
  color: inherit;
  text-decoration: none;
}

code,
kbd,
tt,
var,
samp,
pre {
  font-size: 0.825rem;
  line-height: 1.625;
}

code {
  background: #f9f9f9;
  display: block;
  font-weight: 400;
  padding: 1rem;
}

pre {
  border: 1px solid #009bd4;
  font-weight: 400;
  margin-bottom: 1rem;
  max-width: 100%;
  overflow: auto;
  padding: 0.75rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

.icon {
  height: 75px;
  width: 75px;
}

.logo {
  width: 75px;
}

.theme--blue {
  background-color: #009bd4;
  color: #fff;
  transition: background-color 0.2s, color 0.2s;
}
.theme--blue .icon:not(.tag__svg, .btn__icon),
.theme--blue .logo {
  fill: #fff;
}
.theme--blue a:not([class]) {
  color: #fff;
  text-decoration: underline;
}
.theme--blue a:not([class]):hover {
  text-decoration: none;
}
.theme--blue blockquote > p {
  color: #000;
}
.theme--blue.theme--hover:hover {
  background-color: rgb(8, 188.5896226415, 255);
}
.theme--light-blue {
  background-color: #8dd8f8;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--light-blue .icon:not(.tag__svg, .btn__icon),
.theme--light-blue .logo {
  fill: #000;
}
.theme--light-blue a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--light-blue a:not([class]):hover {
  text-decoration: none;
}
.theme--light-blue blockquote > p {
  color: #fff;
}
.theme--light-blue.theme--hover:hover {
  background-color: rgb(92.9504132231, 199.5619834711, 245.0495867769);
}
.theme--lightest-blue {
  background-color: #c7eafc;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--lightest-blue .icon:not(.tag__svg, .btn__icon),
.theme--lightest-blue .logo {
  fill: #000;
}
.theme--lightest-blue a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--lightest-blue a:not([class]):hover {
  text-decoration: none;
}
.theme--lightest-blue.theme--hover:hover {
  background-color: rgb(150.593220339, 215.8474576271, 249.406779661);
}
.theme--grey {
  background-color: #959595;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--grey .icon:not(.tag__svg, .btn__icon),
.theme--grey .logo {
  fill: #fff;
}
.theme--grey a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--grey a:not([class]):hover {
  text-decoration: none;
}
.theme--grey blockquote > p {
  color: #fff;
}
.theme--grey.theme--hover:hover {
  background-color: rgb(123.5, 123.5, 123.5);
}
.theme--light-grey {
  background-color: #e1e2e3;
  color: #000;
}
.theme--light-grey .icon:not(.tag__svg, .btn__icon),
.theme--light-grey .logo {
  fill: #000;
}
.theme--light-grey a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--light-grey a:not([class]):hover {
  text-decoration: none;
}
.theme--light-grey.theme--hover:hover {
  background-color: rgb(198.6206896552, 200.5, 202.3793103448);
}
.theme--dark-grey {
  background-color: #434343;
  color: #fff;
}
.theme--dark-grey .icon:not(.tag__svg, .btn__icon),
.theme--dark-grey .logo {
  fill: #fff;
}
.theme--dark-grey a:not([class]) {
  color: #fff;
  text-decoration: underline;
}
.theme--dark-grey a:not([class]):hover {
  text-decoration: none;
}
.theme--dark-grey.theme--hover:hover {
  background-color: rgb(41.5, 41.5, 41.5);
}
.theme--white {
  background-color: #fff;
  color: #000;
}
.theme--white .icon:not(.tag__svg, .btn__icon),
.theme--white .logo {
  fill: #434343;
}
.theme--white a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--white a:not([class]):hover {
  text-decoration: none;
}
.theme--white.theme--hover:hover {
  background-color: rgb(229.5, 229.5, 229.5);
}

.actions-bar__list {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0 0;
}
.actions-bar__item {
  flex: 0 1 auto;
  margin-right: 1.3rem;
}
.actions-bar__item:last-child {
  margin-right: 0;
}

.banner {
  margin-bottom: -1px;
  min-height: 190px;
  overflow: hidden;
  position: relative;
}
.banner--small {
  min-height: calc((768px) / 3);
  padding-bottom: 0;
}
.banner__breadcrumbs {
  bottom: 0;
  max-width: 100%;
  position: absolute;
}
.banner__heading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .banner__heading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .banner__heading {
    font-size: 2.5rem;
  }
}
.banner__heading {
  margin-bottom: 1rem;
  padding: 0;
}
.banner__subheading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .banner__subheading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .banner__subheading {
    font-size: 2.5rem;
  }
}
.banner__subheading {
  color: #009bd4;
  padding: 0;
  width: 80%;
}
.banner__content {
  max-width: 800px;
  padding: 1.75rem 24px 2.5rem;
  width: 100%;
}
.banner--small .banner__content {
  height: auto;
  position: relative;
  top: auto;
}
.banner__text {
  font-size: 1.125rem;
}
.banner__text blockquote {
  text-align: left;
}
.banner__text--small {
  width: 60%;
}
.banner__media {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.banner__image {
  display: block;
  left: 50%;
  margin: auto;
  max-width: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}
.banner__image--video {
  height: 100%;
  width: auto;
}
.banner__link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.banner__button {
  margin-top: 1rem;
}
.banner__icon {
  height: auto;
  width: 100%;
}
.banner__play-btn {
  cursor: pointer;
  left: 75%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 180ms, width 0.2s;
  width: 100px;
}
.banner.theme--blue .banner__subheading {
  color: #000;
}
.banner.theme--light-blue .banner__subheading {
  color: #fff;
}
.banner.theme--grey .banner__subheading {
  color: #fff;
}
.banner--image {
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.banner--image .banner__content {
  height: 100%;
  position: absolute;
  top: 0;
}
.banner--image.theme--blue .banner__subheading, .banner--image.theme--light-blue .banner__subheading, .banner--image.theme--grey .banner__subheading {
  color: #009bd4;
}
.banner--video {
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.banner--video.theme--blue .banner__subheading, .banner--video.theme--light-blue .banner__subheading, .banner--video.theme--grey .banner__subheading {
  color: #009bd4;
}
.banner--hover {
  cursor: pointer;
}
.banner--hover:hover .banner__image {
  transform: scale(1.25);
  transition: transform 1.5s;
  transition-timing-function: ease-in-out;
}
@media only screen and (min-width: 480px) {
  .banner {
    min-height: 300px;
  }
  .banner--image {
    min-height: 300px;
  }
}
@media only screen and (min-width: 768px) {
  .banner {
    min-height: 0;
    padding-bottom: 40%;
  }
  .banner__content {
    height: 100%;
    position: absolute;
    top: 0;
  }
  .banner__image {
    left: auto;
    position: static;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
  }
  .banner__image--video {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .banner--image {
    min-height: 300px;
  }
  .banner--small {
    min-height: calc((768px) / 3);
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .banner__text--small {
    width: 80%;
  }
  .banner__image {
    display: block;
    left: 50%;
    margin: auto;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
  .banner__image--video {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .banner--hover:hover .banner__image {
    transform: translate(-50%, -50%) scale(1.25);
  }
}

.banner-video {
  background: #959595;
  margin-bottom: -1px;
  min-height: 300px;
  overflow: hidden;
  position: relative;
}
.banner-video__content {
  color: #fff;
  max-width: 800px;
  padding: 1.75rem 24px 2.5rem;
  position: relative;
  width: 100%;
}
.banner-video__title {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .banner-video__title {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .banner-video__title {
    font-size: 2.5rem;
  }
}
.banner-video__title {
  color: #fff;
  margin-bottom: 1rem;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.banner-video__subtitle {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .banner-video__subtitle {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .banner-video__subtitle {
    font-size: 2.5rem;
  }
}
.banner-video__subtitle {
  color: #009bd4;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.banner-video__text {
  font-size: 1.125rem;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.banner-video__button {
  margin-top: 1rem;
}
.banner-video__image, .banner-video__video {
  height: 100%;
  object-fit: cover;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
@media only screen and (min-width: 768px) {
  .banner-video {
    aspect-ratio: 1/0.5;
    min-height: 300px;
  }
}

.block {
  color: inherit;
  height: 100%;
  position: relative;
  width: 100%;
}
.block:hover .block__image {
  filter: grayscale(0);
}
.block:hover .block__overlay {
  background-color: transparent;
}
.block__btn {
  color: inherit;
  position: relative;
}
.block__btn::before {
  background-color: inherit;
}
.block__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.75rem 1.5rem;
  position: relative;
  width: 100%;
}
.block__media {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.block__overlay {
  background-color: rgba(0, 0, 0, 0.6);
  inset: 0;
  position: absolute;
  transition: background-color 0.2s;
}
.block__overlay--blue {
  background-color: rgba(0, 155, 212, 0.5);
}
.block__overlay--grey {
  background-color: rgba(0, 0, 0, 0.6);
}
.block__overlay--darkgrey {
  background-color: rgba(0, 0, 0, 0.7);
}
.block__overlay--lightgrey {
  background-color: rgba(225, 226, 227, 0.5);
}
.block__image {
  display: block;
  margin: auto;
  transition: filter 0.2s;
  width: 100%;
}
.block__header {
  position: relative;
}
.block__heading {
  font-size: 1.4rem;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .block__heading {
    font-size: 1.7rem;
  }
}
.block__heading {
  margin-bottom: 0.4rem;
}
.block__subheading {
  font-size: inherit;
}
.block__footer {
  bottom: 0;
  padding: 1.75rem 0;
  position: absolute;
  width: 100%;
}
.block__link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.block__link span {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.block__text {
  margin-bottom: 1.5rem;
  max-height: 10em;
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;
}
.block__text::before {
  bottom: 0;
  content: "...";
  padding: 0 1em;
  position: absolute;
  right: 1.6rem;
}
.block__text::after {
  content: "";
  height: 1em;
  margin-top: -1em;
  position: absolute;
  right: 1.5rem;
  width: 5em;
}
.block__category {
  bottom: 1.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1;
  padding: 0 0 0 1.4rem;
  position: absolute;
  text-transform: uppercase;
}
.block__category::before {
  content: "";
  display: block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 12px;
}
.theme--blue .block__category::before {
  background-color: #fff;
}
.theme--light-blue .block__category::before {
  background-color: #000;
}
.theme--lightest-blue .block__category::before {
  background-color: #000;
}
.theme--grey .block__category::before {
  background-color: #000;
}
.theme--light-grey .block__category::before {
  background-color: #000;
}
.theme--dark-grey .block__category::before {
  background-color: #fff;
}
.theme--white .block__category::before {
  background-color: #fff;
}
.block__tags {
  bottom: 1.5rem;
  display: flex;
  flex-flow: row-reverse wrap;
  gap: 0.2rem;
  left: 1.5rem;
  margin: auto -0.2rem 0;
}
.block__tag {
  background-color: #fff;
  padding: 0.4rem;
}
.block .event-info {
  color: #009bd4;
  margin: 0;
}
.theme--blue .block .event-info {
  color: #fff;
}
.theme--blue .block .block__text::after {
  background-color: #009bd4;
  color: #fff;
  transition: background-color 0.2s, color 0.2s;
}
.theme--blue .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--blue .block .block__text::after .logo {
  fill: #fff;
}
.theme--blue .block .block__text::after a:not([class]) {
  color: #fff;
  text-decoration: underline;
}
.theme--blue .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--blue .block .block__text::after blockquote > p {
  color: #000;
}
.theme--blue .block .block__btn {
  color: #fff;
}
.theme--blue .block .block__btn::before {
  background-color: #fff;
}
.theme--blue .block .block__btn::after {
  border-color: #fff;
}
.theme--blue .block:hover .block__text::after {
  background-color: rgb(8, 188.5896226415, 255);
}
.theme--light-blue .block .event-info {
  color: #fff;
}
.theme--light-blue .block .block__text::after {
  background-color: #8dd8f8;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--light-blue .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--light-blue .block .block__text::after .logo {
  fill: #000;
}
.theme--light-blue .block .block__text::after a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--light-blue .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--light-blue .block .block__text::after blockquote > p {
  color: #fff;
}
.theme--light-blue .block .block__btn {
  color: #000;
}
.theme--light-blue .block .block__btn::before {
  background-color: #000;
}
.theme--light-blue .block .block__btn::after {
  border-color: #000;
}
.theme--light-blue .block:hover .block__text::after {
  background-color: rgb(92.9504132231, 199.5619834711, 245.0495867769);
}
.theme--lightest-blue .block .event-info {
  color: #000;
}
.theme--lightest-blue .block .block__text::after {
  background-color: #c7eafc;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--lightest-blue .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--lightest-blue .block .block__text::after .logo {
  fill: #000;
}
.theme--lightest-blue .block .block__text::after a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--lightest-blue .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--lightest-blue .block .block__btn {
  color: #000;
}
.theme--lightest-blue .block .block__btn::before {
  background-color: #000;
}
.theme--lightest-blue .block .block__btn::after {
  border-color: #000;
}
.theme--lightest-blue .block:hover .block__text::after {
  background-color: rgb(150.593220339, 215.8474576271, 249.406779661);
}
.theme--grey .block .event-info {
  color: #fff;
}
.theme--grey .block .block__text::after {
  background-color: #959595;
  color: #000;
  transition: background-color 0.2s, color 0.2s;
}
.theme--grey .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--grey .block .block__text::after .logo {
  fill: #fff;
}
.theme--grey .block .block__text::after a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--grey .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--grey .block .block__text::after blockquote > p {
  color: #fff;
}
.theme--grey .block .block__btn {
  color: #000;
}
.theme--grey .block .block__btn::before {
  background-color: #000;
}
.theme--grey .block .block__btn::after {
  border-color: #000;
}
.theme--grey .block:hover .block__text::after {
  background-color: rgb(123.5, 123.5, 123.5);
}
.theme--light-grey .block .event-info {
  color: #000;
}
.theme--light-grey .block .block__text::after {
  background-color: #e1e2e3;
  color: #000;
}
.theme--light-grey .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--light-grey .block .block__text::after .logo {
  fill: #000;
}
.theme--light-grey .block .block__text::after a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--light-grey .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--light-grey .block .block__btn {
  color: #000;
}
.theme--light-grey .block .block__btn::before {
  background-color: #000;
}
.theme--light-grey .block .block__btn::after {
  border-color: #000;
}
.theme--light-grey .block:hover .block__text::after {
  background-color: rgb(198.6206896552, 200.5, 202.3793103448);
}
.theme--dark-grey .block .event-info {
  color: #009bd4;
}
.theme--dark-grey .block .block__text::after {
  background-color: #434343;
  color: #fff;
}
.theme--dark-grey .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--dark-grey .block .block__text::after .logo {
  fill: #fff;
}
.theme--dark-grey .block .block__text::after a:not([class]) {
  color: #fff;
  text-decoration: underline;
}
.theme--dark-grey .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--dark-grey .block .block__btn {
  color: #fff;
}
.theme--dark-grey .block .block__btn::before {
  background-color: #fff;
}
.theme--dark-grey .block .block__btn::after {
  border-color: #fff;
}
.theme--dark-grey .block:hover .block__text::after {
  background-color: rgb(41.5, 41.5, 41.5);
}
.theme--white .block .event-info {
  color: #000;
}
.theme--white .block .block__text::after {
  background-color: #fff;
  color: #000;
}
.theme--white .block .block__text::after .icon:not(.tag__svg, .btn__icon),
.theme--white .block .block__text::after .logo {
  fill: #434343;
}
.theme--white .block .block__text::after a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.theme--white .block .block__text::after a:not([class]):hover {
  text-decoration: none;
}
.theme--white .block .block__btn {
  color: #000;
}
.theme--white .block .block__btn::before {
  background-color: #000;
}
.theme--white .block .block__btn::after {
  border-color: #000;
}
.theme--white .block:hover .block__text::after {
  background-color: rgb(229.5, 229.5, 229.5);
}
.block--img {
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
.block--img .event-info {
  color: #fff;
}
.block--img .block__btn {
  color: #fff;
}
.block--img .block__btn::before {
  background-color: #fff;
}
.block--img .block__btn::after {
  border-color: #fff;
}
.block--img .block__content {
  height: 100%;
}
.block--img .block__content::before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 75%, transparent);
  content: "";
  transform-origin: top center;
  transition: background 0.2s ease-in, translate 0.2s ease-in;
  transition-behavior: allow-discrete;
  translate: 0 -50%;
}
.block--img:focus .block__content::before, .block--img:hover .block__content::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 100%, transparent);
  translate: 0 0;
}
.block--filter .block__image {
  filter: grayscale(1);
}
.block--filter:hover .block__image {
  filter: grayscale(0);
}

.block-overlay {
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  opacity: 0;
  padding: 1rem;
  position: fixed;
  transition: opacity 0.2s;
  width: 100%;
  z-index: 1000;
}
.block-overlay.is-hidden {
  display: none;
}
.block-overlay.is-active {
  opacity: 1;
}
.block-overlay__inner {
  background-color: #009bd4;
  margin: 0 auto;
  max-height: 100%;
  max-width: 480px;
  overflow: auto;
  position: relative;
}
.block-overlay__content {
  color: #fff;
  padding: 1.75rem 1.5rem;
}
.block-overlay__close {
  background: rgba(0, 0, 0, 0.3);
  line-height: 1;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.block-overlay__close:hover {
  background: rgba(0, 0, 0, 0.5);
}
.block-overlay__icon {
  fill: #fff;
  height: 16px;
  width: 16px;
}
.block-overlay__header {
  left: 0;
  padding: 0 1.5rem 0.5rem;
  position: absolute;
  top: 250px;
  transform: translateY(-100%);
  z-index: 1;
}
.block-overlay__heading {
  font-size: 1.4rem;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .block-overlay__heading {
    font-size: 1.7rem;
  }
}
.block-overlay__heading {
  margin-bottom: 1rem;
}
.block-overlay__subheading {
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
.block-overlay__text {
  max-height: 400px;
  overflow: hidden;
}
.block-overlay__text a {
  color: #fff;
  text-decoration: underline;
}
.block-overlay__text a:hover {
  color: #fff;
  text-decoration: none;
}
.block-overlay__media {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.block-overlay__media::after {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  inset: 0;
  position: absolute;
}
.block-overlay__picture, .block-overlay__image {
  display: block;
  height: 100%;
  max-width: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}
@media only screen and (min-width: 768px) {
  .block-overlay {
    background: none;
    bottom: auto;
    padding: 0;
    position: absolute;
    z-index: auto;
  }
  .block-overlay__inner {
    display: flex;
    height: 379px;
    max-width: none;
  }
  .block-overlay__header {
    left: auto;
    padding: 0;
    position: relative;
    top: auto;
    transform: none;
    z-index: auto;
  }
  .block-overlay__content {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    width: 379px;
  }
  .block-overlay__text {
    max-height: none;
    overflow: hidden;
  }
  .block-overlay__media {
    flex: 0 0 auto;
    order: 1;
    padding-bottom: 379px;
    width: 379px;
  }
  .block-overlay__media::after {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .block-overlay__inner {
    height: 333.3333333333px;
  }
  .block-overlay__subheading {
    font-size: 1.2rem;
  }
  .block-overlay__content {
    width: 666.6666666667px;
  }
  .block-overlay__media {
    flex: 0 0 auto;
    padding-bottom: 333.3333333333px;
    width: 333.3333333333px;
  }
}

.breadcrumbs {
  background-color: #959595;
  display: flex;
  font-size: 13px;
}
.breadcrumbs__back {
  background-color: #434343;
  flex: 0 0 auto;
  font-size: 23px;
  font-weight: 700;
  height: 38px;
  line-height: 38px;
  position: relative;
  text-align: center;
  transition: background-color 0.2s;
  width: 38px;
}
.breadcrumbs__back:hover {
  background-color: #000;
}
.breadcrumbs__links {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 0.8rem;
}
.breadcrumbs__link {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .breadcrumbs__link {
    letter-spacing: 0.75px;
  }
}
.breadcrumbs__link {
  background-color: #959595;
  color: #fff;
  flex: 0 0 auto;
  font-size: 0.9rem;
  height: 38px;
  line-height: 38px;
  text-transform: uppercase;
  transition: color 0.2s;
  white-space: nowrap;
}
.breadcrumbs__link::before {
  content: "/";
  padding: 0 0.4rem;
}
.breadcrumbs__link:hover {
  color: #000;
}
.breadcrumbs__link:first-child::before {
  display: none;
}
.breadcrumbs__icon {
  fill: #fff;
  height: 18px;
  left: 48%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
}

.btn {
  background-color: #009bd4;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  height: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s background-color;
}
.btn:hover {
  background-color: rgb(0, 117.7122641509, 161);
  color: #fff;
}
.btn__icon {
  fill: #fff;
  height: 16px;
  width: 16px;
}
.btn--text {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 0.9rem;
  height: auto;
  line-height: inherit;
  padding: 0 0 0 1.4rem;
  text-align: left;
}
.btn--text::before {
  background: #fff;
  content: "";
  display: block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 1px;
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: 12px;
}
.btn--text::after {
  border-bottom: solid 2px rgba(255, 255, 255, 0.7);
  content: "";
  display: block;
  opacity: 0;
  position: relative;
  transform: translateY(0);
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.btn--text:hover {
  background: transparent;
}
.btn--text:hover::before {
  transform: rotate(90deg);
}
.btn--text:hover::after {
  opacity: 1;
}
.btn--text.btn--blue {
  color: #009bd4;
}
.btn--text.btn--blue::before {
  background: #009bd4;
}
.btn--text.btn--blue::after {
  border-bottom: solid 2px rgba(0, 155, 212, 0.7);
}
.btn--search {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  flex: 0 0 auto;
}
.btn--lightblue {
  background-color: #8dd8f8;
}
.btn--lightblue:hover {
  background-color: rgb(92.9504132231, 199.5619834711, 245.0495867769);
}
.btn--grey {
  background-color: #959595;
}
.btn--grey:hover {
  background-color: rgb(123.5, 123.5, 123.5);
}
.btn--darkgrey {
  background-color: #434343;
}
.btn--darkgrey:hover {
  background-color: rgb(41.5, 41.5, 41.5);
}
.btn--white {
  background-color: #fff;
  color: #009bd4;
}
.btn--white .btn__icon {
  fill: #009bd4;
}
.btn--white:hover {
  background-color: rgb(229.5, 229.5, 229.5);
}
.btn--outline {
  background: #fff;
  border: solid 1px #009bd4;
  color: #009bd4;
  padding: 0 15px;
}
.btn--outline .btn__icon {
  fill: #009bd4;
}
.btn--outline:hover {
  background: #009bd4;
}
.btn--outline:hover .btn__icon {
  fill: #fff;
}
.btn--outline.btn--lightblue {
  border-color: #8dd8f8;
  color: #8dd8f8;
}
.btn--outline.btn--lightblue .btn__icon {
  fill: #8dd8f8;
}
.btn--outline.btn--lightblue:hover {
  background-color: rgb(92.9504132231, 199.5619834711, 245.0495867769);
  color: #fff;
}
.btn--outline.btn--lightblue:hover .btn__icon {
  fill: #fff;
}
.btn--outline.btn--grey {
  border-color: #959595;
  color: #959595;
}
.btn--outline.btn--grey .btn__icon {
  fill: #959595;
}
.btn--outline.btn--grey:hover {
  background-color: #959595;
  color: #fff;
}
.btn--outline.btn--grey:hover .btn__icon {
  fill: #fff;
}
.btn--outline.btn--darkgrey {
  border-color: #434343;
  color: #434343;
}
.btn--outline.btn--darkgrey .btn__icon {
  fill: #434343;
}
.btn--outline.btn--darkgrey:hover {
  background-color: #434343;
  color: #fff;
}
.btn--outline.btn--darkgrey:hover .btn__icon {
  fill: #fff;
}

.content__heading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .content__heading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .content__heading {
    font-size: 2.5rem;
  }
}
.content__heading {
  margin: 0 0 0.6rem;
}
.content__subheading {
  font-size: inherit;
  margin: 0;
}
.content__container {
  max-width: 800px;
  padding: 3rem 24px;
}
.content__info {
  margin: 0 0 1rem;
}

.downloads {
  background: #009bd4;
  height: auto;
  padding: 1rem 24px;
  width: 100%;
}
.downloads > h3 {
  font-family: "Neue Helvetica W01", "Helvetica", "sans-serif";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
}
@media only screen and (min-width: 480px) {
  .downloads > h3 {
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .downloads > h3 {
    font-size: 1.05rem;
  }
}
@media only screen and (min-width: 1024px) {
  .downloads > h3 {
    font-size: 1.125rem;
  }
}
.downloads > h3 {
  color: #fff;
  font-size: 1.525rem;
  font-weight: 700;
  margin: 10px 0 20px;
}
.downloads__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.downloads__item {
  margin-bottom: 0.4rem;
}
.downloads__link {
  align-items: center;
  color: #fff;
  display: flex;
  transition: color 0.2s;
}
.downloads__link:hover {
  color: #000;
}
.downloads__link:hover .downloads__icon {
  background-color: #000;
}
.downloads__link:hover .downloads__svg {
  fill: #fff;
}
.downloads__icon {
  background-color: #fff;
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  position: relative;
  transition: background-color 0.2s;
  width: 24px;
}
.downloads__svg {
  fill: #009bd4;
  height: 14px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: fill 0.2s;
  width: 14px;
}
.downloads__title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .downloads__title {
    letter-spacing: 0.75px;
  }
}
.downloads__title {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

@keyframes slide-in-to-right {
  from {
    transform: translateX(-110%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-in-to-left {
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-in-to-bottom {
  from {
    transform: translateY(-110%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out-to-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(110%);
  }
}
@keyframes slide-out-to-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-110%);
  }
}
@keyframes slide-out-to-top {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-110%);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-to-top {
  0% {
    opacity: 0;
    transform: translate(var(--horizontal-animation-translate, 0), var(--vertical-animation-translate, 100%));
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
:root {
  --drawer-search-background-color: #009bd4;
  --drawer-search-color: #fff;
  --drawer-search-column-gap: 4rem;
  --drawer-search-close-background-color: #fff;
  --drawer-search-close-background-hover-color: #8dd8f8;
  --drawer-search-close-icon-color: #009bd4;
  --drawer-search-close-icon-hover-color: #fff;
  --drawer-search-header-logo-width: 75px;
}

.drawer-search {
  background-color: var(--drawer-search-background-color);
  border: none;
  color: var(--drawer-search-color);
  height: fit-content;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 10;
}
.drawer-search[open] {
  animation: fade-in 500ms 0ms forwards;
}
.drawer-search.is-closed {
  animation: fade-out 500ms 0ms forwards;
}
.drawer-search__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding: 0 24px 24px;
  width: 100%;
}
.drawer-search__header {
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
}
.drawer-search__link {
  display: block;
}
.drawer-search__logo {
  display: none;
  width: var(--drawer-search-header-logo-width);
}
.drawer-search__logo-text {
  height: 35px !important;
  width: 130px !important;
}
.drawer-search__close {
  align-items: center;
  background-color: var(--drawer-search-close-background-color);
  border-radius: 0;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  width: 40px;
}
.drawer-search__icon-close {
  fill: var(--drawer-search-close-icon-color);
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 1280px) {
  .drawer-search__header {
    justify-content: flex-end;
    position: relative;
  }
  .drawer-search__branding {
    left: calc(var(--drawer-search-header-logo-width) * -1 - 4rem);
    position: absolute;
    top: 6px;
  }
  .drawer-search__logo {
    display: block;
  }
  .drawer-search__logo-text {
    display: none;
  }
}

.section.event-details {
  height: 0;
  padding: 10px 0 100%;
  width: 100%;
}
.section.event-details .btn {
  margin-left: 10px;
}

.event-info {
  font-size: 1rem;
  margin-bottom: 20px;
  padding: 0 10px;
}
.event-info--heading {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .event-info--heading {
    letter-spacing: 0.75px;
  }
}
.event-info__label {
  display: block;
  width: 100px;
}
.event-info__value {
  font-weight: bold;
}

.filters__select {
  position: relative;
}
.filters__bar {
  background-color: #959595;
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
}
.filters__title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .filters__title {
    letter-spacing: 0.75px;
  }
}
.filters__title {
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  padding: 1rem 0;
}
.filters__toggle {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .filters__toggle {
    letter-spacing: 0.75px;
  }
}
.filters__toggle {
  background: #808080;
  border: 0;
  color: #fff;
  font-size: 1rem;
  outline: 0;
  padding: 1rem 1.5rem;
  transition: background 0.2s;
}
.filters__toggle:hover {
  background: #434343;
}
.filters__toggle.is-active {
  background: #009bd4;
}
.filters__toggle-icon {
  fill: #fff;
  height: 16px;
  margin-right: 0.2rem;
  vertical-align: -2px;
  width: 16px;
}
.filters__options {
  background: #009bd4;
  max-height: 0;
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;
  transition: max-height 0.2s, padding 0.2s;
  width: 100%;
  z-index: 1;
}
.filters__options::after {
  background-color: transparent;
  content: "";
  height: 2px;
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 100%;
  transition: background-color 0.2s 0.1s;
}
.filters__options.is-active {
  max-height: fit-content;
  padding: 1rem 1.5rem;
}
.filters__options.is-active::after {
  background-color: #fff;
}
.filters__options-section {
  margin-bottom: 1rem;
}
.filters__options-section:last-child {
  margin-bottom: 0;
}
.filters__options-title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .filters__options-title {
    letter-spacing: 0.75px;
  }
}
.filters__options-title {
  color: #000;
  margin-bottom: 0.3rem;
}
.filters__selected {
  background: #009bd4;
  padding: 1rem 1.5rem;
}
.filters__selected.is-hidden {
  display: none;
}
.filters__list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.3rem;
}
.filters__item {
  padding: 0.3rem;
}
@media only screen and (min-width: 480px) {
  .filters__title, .filters__toggle, .filters__options-title {
    font-size: 1.1rem;
  }
}

.footer {
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .footer {
    padding-bottom: 80px;
  }
}
.footer p {
  line-height: 1.35;
  margin: 0;
}
.footer__buttons {
  display: flex;
}
.footer__buttons .footer__text {
  padding-right: 1rem;
}
.footer__container {
  padding: 2rem 1.5rem;
}
@media only screen and (min-width: 768px) {
  .footer__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 -2.5%;
  }
}
.footer__item {
  font-size: 1rem;
  margin: 0 0 3rem;
}
@media only screen and (min-width: 768px) {
  .footer__item {
    margin: 0 2.5% 3rem;
    width: 45%;
  }
  .footer__item--wide {
    width: 80%;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__item {
    width: 20%;
  }
  .footer__item--wide {
    width: 40%;
  }
}
.footer__item:last-of-type {
  margin-bottom: 0;
}
.footer__header {
  margin: 0 0 2rem;
}
.footer__text {
  margin: 0 0 1rem;
}
.footer__text:last-of-type {
  margin: 0;
}
.footer__text span {
  color: #009bd4;
}
.footer__text--bold {
  font-weight: 700;
}
.footer__link {
  color: #8dd8f8;
}
.footer__link:hover {
  color: #c7eafc;
}
.footer.theme--darkgrey:hover {
  background-color: #434343;
}

.form body {
  background-color: #fff;
}
.form .section {
  clear: left;
}
.form .corner {
  background: #fff;
  border-radius: 0;
  margin: 0;
  padding: 10px 25px 5px;
  width: 550px;
}
.form h2 {
  color: #434343;
  font-size: 1.125rem;
  margin: 0;
  text-transform: uppercase;
}
.form td {
  border: unset;
  text-align: left;
}
.form .rightalligned {
  text-align: right;
}
.form .field {
  font-size: 1rem;
}
.form .field418af6b1-de7e-4adf-a98c-1ec9a8ea639a {
  display: none;
}
.form input {
  border: 2px solid #959595;
  color: #434343;
  max-width: 500px;
  outline: none;
  padding: 0.5rem;
  transition: border 0.2s ease;
}
.form input:focus {
  border-color: #434343;
}
.form select {
  border: 2px solid #959595;
  color: #434343;
  max-width: 500px;
  outline: none;
  padding: 0.5rem;
  transition: border 0.2s ease;
}
.form label {
  color: #434343;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.form .helptext {
  color: #000;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
.form .button {
  background-color: #009bd4;
  border: 0;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  margin: 5px 5px 0 0;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: ease 0.3s;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}
.form .button:hover {
  background-color: #434343;
}
.form .error {
  border-color: #f00;
  border-style: solid;
  border-width: 1px;
}

.section.event-map {
  height: 0;
  overflow: hidden;
  padding: 0 0 100%;
  position: relative;
  width: 100%;
}

.header {
  align-items: center;
  background: #fff;
  display: flex;
  height: 72px;
  padding: 0 24px;
  position: relative;
}
.header__heading {
  color: #434343;
}
.header__brand {
  margin-right: auto;
}
.header__logo {
  display: none;
  width: 75px;
}
.header__logo-text {
  height: 35px;
  width: 130px;
}
.header__payoff {
  display: none;
  padding-top: 5px;
  width: 75px;
}
.header__link {
  display: block;
}
.header__main {
  box-shadow: 0 4px 2px -1px rgba(0, 0, 0, 0.2);
  left: 0;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 10;
}
.header__main.is-open {
  max-height: 1000px;
  transition: max-height 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.header__secondary {
  order: 2;
}
@media only screen and (min-width: 768px) {
  .header {
    padding: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .header__brand {
    line-height: 28px;
  }
  .header__main {
    box-shadow: none;
    display: block;
    margin-right: 2rem;
    max-height: none;
    order: 2;
    overflow: visible;
    position: static;
    top: auto;
    width: auto;
  }
  .header__secondary {
    order: 3;
  }
}
@media only screen and (min-width: 1230px) {
  .header {
    justify-content: flex-end;
  }
  .header__brand {
    bottom: auto;
    left: -107px;
    line-height: 32px;
    margin: 1rem 0 0;
    position: absolute;
    top: -1px;
  }
  .header__logo, .header__payoff {
    display: block;
  }
  .header__logo-text {
    display: none;
  }
}

.highlighted-page {
  background-color: #f9f9f9;
  min-height: 300px;
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 480px) {
  .highlighted-page {
    min-height: 500px;
  }
}
@media only screen and (min-width: 768px) {
  .highlighted-page {
    min-height: 0;
  }
}
.highlighted-page--small {
  min-height: calc((768px) / 3);
  padding-bottom: 0;
}
.highlighted-page__heading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .highlighted-page__heading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .highlighted-page__heading {
    font-size: 2.5rem;
  }
}
.highlighted-page__heading {
  margin-bottom: 1rem;
  padding: 0;
}
.highlighted-page__subheading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .highlighted-page__subheading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .highlighted-page__subheading {
    font-size: 2.5rem;
  }
}
.highlighted-page__subheading {
  font-weight: bold;
  padding: 0;
  width: 80%;
}
.highlighted-page__content {
  padding: 1.75rem 24px 2.5rem;
  width: 100%;
}
.highlighted-page--small .highlighted-page__content {
  height: auto;
  position: relative;
  top: auto;
}
@media only screen and (min-width: 768px) {
  .highlighted-page__content {
    height: 100%;
  }
}
.highlighted-page__text {
  font-size: 1.1rem;
}
.highlighted-page__media {
  display: inline-block;
  height: 300px;
  margin: 0;
  max-height: 300px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.highlighted-page__media .media {
  position: absolute;
  top: -33%;
}
.highlighted-page__image {
  display: block;
  left: 50%;
  margin: auto;
  max-width: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}
.highlighted-page__image--video {
  height: 100%;
  width: auto;
}
@media only screen and (min-width: 768px) {
  .highlighted-page__image {
    left: auto;
    position: static;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
  }
  .highlighted-page__image--video {
    height: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .highlighted-page__image {
    display: block;
    left: 50%;
    margin: auto;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
  .highlighted-page__image--video {
    height: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
.highlighted-page__link {
  margin-top: 20px;
}
.highlighted-page__icon {
  height: auto;
  width: 100%;
}
.highlighted-page__overlay {
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: absolute;
}
.highlighted-page.theme--blue .banner__subheading {
  color: #000;
}
.highlighted-page.theme--light-blue .banner__subheading {
  color: #fff;
}
.highlighted-page--image {
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}
@media only screen and (min-width: 480px) {
  .highlighted-page--image {
    min-height: 300px;
  }
}
@media only screen and (min-width: 768px) {
  .highlighted-page--image {
    min-height: 300px;
  }
}
.highlighted-page--image .banner__content {
  height: 100%;
  position: absolute;
  top: 0;
}
.highlighted-page--hover {
  cursor: pointer;
}
.highlighted-page--hover:hover .banner__image {
  transform: translate(-50%, -50%) scale(1.25);
  transition: transform 1.5s;
  transition-timing-function: ease-in-out;
}

.legacy-warning {
  background: #009bd4;
  height: 100%;
  inset: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.legacy-warning__container {
  display: flex;
  height: 100%;
  width: 100%;
}
.legacy-warning__content {
  background: #fff;
  margin: auto;
  max-width: 400px;
  padding: 1.5rem;
  text-align: center;
}
.legacy-warning__heading {
  margin: 0 0 0.7rem;
}

.list {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.625;
}
.list__item {
  padding-left: 1.25rem;
  position: relative;
  text-transform: uppercase;
}
.list__item::before {
  background-color: #000;
  content: "";
  display: block;
  font-size: 1em;
  height: 14px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 14px;
}
.list.theme--blue {
  background: transparent;
  color: #009bd4;
}
.list.theme--blue .list__item::before {
  background-color: #009bd4;
}

.loader {
  margin: 20px;
  text-align: center;
}
.loader img {
  height: 50px;
  width: 50px;
}

.media {
  display: block;
  position: relative;
}
.media--video {
  height: 0;
  padding-top: 56.25%;
  position: relative;
}
.media--video embed,
.media--video object,
.media--video iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mediablock {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.mediablock__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  margin: 0;
}
.mediablock__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  display: block;
}
.mediablock__content {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  color: #fff;
  height: auto;
  overflow: hidden;
  padding: 1.75rem 1.5rem;
}
.mediablock__heading {
  margin: 0 0 1rem;
  position: relative;
}
.mediablock__title {
  font-size: 1.4rem;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .mediablock__title {
    font-size: 1.7rem;
  }
}
.mediablock__subtitle {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}
.mediablock__button {
  align-items: center;
  border-radius: 0;
  bottom: 0;
  display: flex;
  gap: 6px;
  justify-content: center;
  position: absolute;
  right: 0;
}
.mediablock__link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.mediablock__link span {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.mediablock__play {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
  transform-origin: center;
  transition: width 0.2s;
  width: 70px;
}
.mediablock__icon {
  fill: #fff;
  height: auto;
  width: 100%;
}
.mediablock--image, .mediablock--video {
  background-color: #009bd4;
}
.mediablock--image .mediablock__content, .mediablock--video .mediablock__content {
  height: auto;
}
.mediablock--image .mediablock__content::before, .mediablock--video .mediablock__content::before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 75%, transparent);
  content: "";
  transform-origin: top center;
  transition: background 0.2s ease-in, translate 0.2s ease-in;
  transition-behavior: allow-discrete;
  translate: 0 -50%;
}
.mediablock--video {
  cursor: pointer;
}
.mediablock--link:focus .mediablock__play, .mediablock--link:hover .mediablock__play {
  width: 90px;
}
.mediablock--link:focus .mediablock__button .btn__icon, .mediablock--link:hover .mediablock__button .btn__icon {
  animation: 0.5s bounce;
}
.mediablock--link.mediablock--image:focus .mediablock__content::before, .mediablock--link.mediablock--image:hover .mediablock__content::before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 100%, transparent);
  translate: 0 0;
}
@media only screen and (min-width: 768px) {
  .mediablock__play {
    width: 100px;
  }
  .mediablock--link:hover .mediablock__play {
    width: 125px;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0);
  }
}
.media-blocks {
  margin: 0 auto;
}
.media-blocks__item > :first-child {
  padding-bottom: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .media-blocks__item {
    opacity: 0;
  }
  .media-blocks.in-view {
    --vertical-animation-translate: 10%;
  }
  .media-blocks.in-view .media-blocks__item {
    animation: fade-in-to-top cubic-bezier(0.25, 0.46, 0.45, 0.94) 500ms 250ms forwards;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(1) {
    animation-delay: 0s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(2) {
    animation-delay: 0.15s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(3) {
    animation-delay: 0.3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(4) {
    animation-delay: 0.45s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(5) {
    animation-delay: 0.6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(6) {
    animation-delay: 0.75s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(7) {
    animation-delay: 0.9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(8) {
    animation-delay: 1.05s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(9) {
    animation-delay: 1.2s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(10) {
    animation-delay: 1.35s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(11) {
    animation-delay: 1.5s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(12) {
    animation-delay: 1.65s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(13) {
    animation-delay: 1.8s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(14) {
    animation-delay: 1.95s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(15) {
    animation-delay: 2.1s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(16) {
    animation-delay: 2.25s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(17) {
    animation-delay: 2.4s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(18) {
    animation-delay: 2.55s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(19) {
    animation-delay: 2.7s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(20) {
    animation-delay: 2.85s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(21) {
    animation-delay: 3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(22) {
    animation-delay: 3.15s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(23) {
    animation-delay: 3.3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(24) {
    animation-delay: 3.45s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(25) {
    animation-delay: 3.6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(26) {
    animation-delay: 3.75s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(27) {
    animation-delay: 3.9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(28) {
    animation-delay: 4.05s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(29) {
    animation-delay: 4.2s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(30) {
    animation-delay: 4.35s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(31) {
    animation-delay: 4.5s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(32) {
    animation-delay: 4.65s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(33) {
    animation-delay: 4.8s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(34) {
    animation-delay: 4.95s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(35) {
    animation-delay: 5.1s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(36) {
    animation-delay: 5.25s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(37) {
    animation-delay: 5.4s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(38) {
    animation-delay: 5.55s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(39) {
    animation-delay: 5.7s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(40) {
    animation-delay: 5.85s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(41) {
    animation-delay: 6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(42) {
    animation-delay: 6.15s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(43) {
    animation-delay: 6.3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(44) {
    animation-delay: 6.45s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(45) {
    animation-delay: 6.6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(46) {
    animation-delay: 6.75s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(47) {
    animation-delay: 6.9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(48) {
    animation-delay: 7.05s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(49) {
    animation-delay: 7.2s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(50) {
    animation-delay: 7.35s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(51) {
    animation-delay: 7.5s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(52) {
    animation-delay: 7.65s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(53) {
    animation-delay: 7.8s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(54) {
    animation-delay: 7.95s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(55) {
    animation-delay: 8.1s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(56) {
    animation-delay: 8.25s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(57) {
    animation-delay: 8.4s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(58) {
    animation-delay: 8.55s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(59) {
    animation-delay: 8.7s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(60) {
    animation-delay: 8.85s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(61) {
    animation-delay: 9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(62) {
    animation-delay: 9.15s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(63) {
    animation-delay: 9.3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(64) {
    animation-delay: 9.45s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(65) {
    animation-delay: 9.6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(66) {
    animation-delay: 9.75s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(67) {
    animation-delay: 9.9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(68) {
    animation-delay: 10.05s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(69) {
    animation-delay: 10.2s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(70) {
    animation-delay: 10.35s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(71) {
    animation-delay: 10.5s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(72) {
    animation-delay: 10.65s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(73) {
    animation-delay: 10.8s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(74) {
    animation-delay: 10.95s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(75) {
    animation-delay: 11.1s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(76) {
    animation-delay: 11.25s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(77) {
    animation-delay: 11.4s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(78) {
    animation-delay: 11.55s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(79) {
    animation-delay: 11.7s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(80) {
    animation-delay: 11.85s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(81) {
    animation-delay: 12s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(82) {
    animation-delay: 12.15s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(83) {
    animation-delay: 12.3s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(84) {
    animation-delay: 12.45s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(85) {
    animation-delay: 12.6s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(86) {
    animation-delay: 12.75s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(87) {
    animation-delay: 12.9s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(88) {
    animation-delay: 13.05s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(89) {
    animation-delay: 13.2s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(90) {
    animation-delay: 13.35s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(91) {
    animation-delay: 13.5s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(92) {
    animation-delay: 13.65s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(93) {
    animation-delay: 13.8s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(94) {
    animation-delay: 13.95s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(95) {
    animation-delay: 14.1s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(96) {
    animation-delay: 14.25s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(97) {
    animation-delay: 14.4s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(98) {
    animation-delay: 14.55s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(99) {
    animation-delay: 14.7s;
  }
  .media-blocks.in-view .media-blocks__item:nth-child(100) {
    animation-delay: 14.85s;
  }
}
@media only screen and (min-width: 480px) {
  .media-blocks__item > :first-child {
    padding-bottom: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .media-blocks {
    display: flex;
    flex-wrap: wrap;
  }
  .media-blocks__item {
    flex: 1 1 50%;
    width: 50%;
  }
  .media-blocks__item > :first-child {
    padding-bottom: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .media-blocks--33-33-33 .media-blocks__item:last-child > :first-child {
    padding-bottom: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .media-blocks {
    flex-wrap: nowrap;
  }
  .media-blocks__item--33 {
    flex-basis: 33.33%;
    width: 33.33%;
  }
  .media-blocks__item--50-sm > :first-child {
    padding-bottom: 66.66%;
  }
  .media-blocks__item--66 {
    flex-basis: 66.66%;
    width: 66.66%;
  }
  .media-blocks__item--66 > :first-child {
    padding-bottom: 50%;
  }
}

.menu-icon {
  display: inline-block;
  height: 15px;
  position: relative;
  width: 18px;
}
.menu-icon__bar {
  background: #434343;
  border-radius: 1px;
  display: block;
  height: 3px;
  position: absolute;
  width: 18px;
}
.menu-icon__bar:nth-child(1) {
  top: 0;
  transition: transform 180ms;
}
.is-open .menu-icon__bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
  transition: transform 180ms 160ms;
}
.menu-icon__bar:nth-child(2) {
  top: 6px;
  transition: width 180ms 180ms;
}
.is-open .menu-icon__bar:nth-child(2) {
  transition: width 180ms;
  width: 0;
}
.menu-icon__bar:nth-child(3) {
  bottom: 0;
  transition: transform 180ms;
}
.is-open .menu-icon__bar:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
  transition: transform 180ms 160ms;
}

.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.nav__dropdown-icon {
  height: 16px;
  transform: rotate(0deg);
  transition: transform 0.25s ease;
  width: 20px;
}
.nav__dropdown-icon.is-open {
  transform: rotate(180deg);
  transition: transform 0.25s ease;
}
.nav__item {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .nav__item {
    letter-spacing: 0.75px;
  }
}
.nav__item {
  margin-left: 0.2rem;
  margin-right: 1rem;
  min-width: 0;
  position: relative;
  text-transform: uppercase;
}
@media only screen and (min-width: 1230px) {
  .nav__item {
    margin-right: 1rem;
  }
}
.nav__item:last-of-type {
  margin-right: 0;
}
.nav__link {
  color: inherit;
  display: inline-block;
  transition: 0.2s ease;
}
.nav__children {
  background-color: #e1e2e3;
  border-top: 3px solid #009bd4;
  display: none;
  height: 0;
  left: 0;
  max-height: 0;
  min-width: 100%;
  overflow: hidden;
  transition: max-height 250ms;
  z-index: 1;
}
.nav__children.is-active {
  display: block;
  height: auto;
}
.nav__children.is-open {
  max-height: 300px;
}
.nav__image {
  display: none;
}
.nav--main .nav__list {
  background-color: #e1e2e3;
  color: #000;
  font-size: 1rem;
  padding: 10px 24px;
  width: 100%;
  z-index: 2;
}
.nav--main .nav__list .icon:not(.tag__svg, .btn__icon),
.nav--main .nav__list .logo {
  fill: #000;
}
.nav--main .nav__list a:not([class]) {
  color: #000;
  text-decoration: underline;
}
.nav--main .nav__list a:not([class]):hover {
  text-decoration: none;
}
.nav--main .nav__item {
  border-bottom: 1px solid rgba(67, 67, 67, 0.3);
  color: #434343;
  display: block;
  line-height: 36px;
  margin: 0;
  padding-left: 1.25rem;
}
.nav--main .nav__item::before {
  background-color: #434343;
  content: "";
  display: block;
  font-size: 1em;
  height: 10px;
  left: 0;
  position: absolute;
  top: 14px;
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: 10px;
}
.nav--main .nav__item--child, .nav--main .nav__item:last-child {
  border-bottom: 0;
}
.nav--main .nav__item.is-active {
  color: #009bd4;
}
.nav--main .nav__link {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.125rem 0;
}
.nav--secondary .nav__list {
  display: flex;
  gap: 0.5rem;
}
.nav--secondary .nav__item {
  cursor: pointer;
  font-size: 1.25rem;
  height: 40px;
  line-height: 40px;
  margin: 0;
  overflow: hidden;
  top: 0;
  width: 40px;
}
.nav--secondary .nav__link {
  color: #fff;
  display: block;
  height: 100%;
  text-align: center;
  width: 100%;
}
.nav--secondary .nav__icon {
  height: 20px;
  width: 20px;
}
.nav--filter .nav__item {
  line-height: 72px;
}
.nav--filter .nav__item:first-of-type {
  color: #000;
}
.nav--filter .nav__children {
  width: 300px;
}
.nav--filter .nav__children .nav__item {
  color: #fff;
  display: block;
}
@media only screen and (min-width: 1024px) {
  .nav__dropdown-icon {
    display: none;
  }
  .nav__item--child {
    color: #434343;
  }
  .nav__children {
    border: 0;
    display: none;
    max-width: none;
    position: absolute;
    top: 72px;
    width: 1000px;
  }
  .nav__children.is-active {
    display: flex;
    height: auto;
  }
  .nav__children.is-open {
    max-height: 600px;
  }
  .nav__children-list {
    flex: 0 0 50%;
    padding: 2rem;
  }
  .nav__image {
    display: block;
    flex: 0 0 50%;
    padding: 2rem;
  }
  .nav--main .nav__list {
    background: transparent;
    display: flex;
    gap: 24px;
    padding: 0;
    position: initial;
  }
  .nav--main .nav__item {
    border-bottom: 0;
    color: #009bd4;
    font-size: 1.125rem;
    padding-left: 0;
    position: initial;
  }
  .nav--main .nav__item::before {
    display: none;
  }
  .nav--main .nav__item--child {
    color: #434343;
  }
  .nav--main .nav__link {
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: color 250ms cubic-bezier(0.19, 1, 0.55, 1), text-decoration 250ms cubic-bezier(0.19, 1, 0.55, 1);
    white-space: nowrap;
  }
  .nav--main .nav__link:focus, .nav--main .nav__link:hover {
    color: #434343;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
  .nav--secondary .nav__item:first-of-type {
    display: none;
  }
}
@media only screen and (min-width: 1280px) {
  .nav__item {
    margin-right: 1rem;
  }
}

.overview {
  position: relative;
}
.overview__header {
  margin: 4rem 24px 0.7rem;
}
@media only screen and (min-width: 768px) {
  .overview__header {
    margin: 4rem 0 0.7rem;
  }
}
.overview__heading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .overview__heading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .overview__heading {
    font-size: 2.5rem;
  }
}
.overview__content {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.overview__filter {
  height: 72px;
  line-height: 72px;
  padding: 0 1.5rem;
}
@media only screen and (min-width: 1024px) {
  .overview__filter {
    height: 72px;
    line-height: 72px;
  }
}
.overview__list::before, .overview__list::after {
  content: " ";
  display: table;
}
.overview__list::after {
  clear: both;
}
.legacy .overview__list::before, .legacy .overview__list::after {
  zoom: 1;
}
.overview__item {
  float: left;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .overview__item {
    padding-bottom: 50%;
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .overview__item {
    padding-bottom: 50%;
    width: 50%;
  }
}
.overview__item--empty {
  display: none;
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .overview__item--empty {
    display: block;
  }
  .overview--square .overview__item--empty:nth-of-type(3n) {
    display: none;
  }
  .overview__item--empty:nth-of-type(9n+6) {
    display: none;
  }
  .overview--square .overview__item--empty:nth-of-type(9n+6) {
    display: block;
  }
  .overview__item:nth-of-type(9n+3) {
    padding-bottom: 100%;
  }
  .overview--square .overview__item:nth-of-type(9n+3) {
    padding-bottom: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .overview__item.cols-3 {
    padding-bottom: 333.3333333333px;
    width: 333.3333333333px;
  }
  .overview__item.cols-3--empty {
    display: block;
  }
  .overview__item.cols-3:nth-of-type(9n+2), .overview__item.cols-3:nth-of-type(9n+3) {
    width: 666.6666666667px;
  }
  .overview--square .overview__item.cols-3:nth-of-type(9n+2), .overview--square .overview__item.cols-3:nth-of-type(9n+3) {
    width: 333.3333333333px;
  }
  .overview__item.cols-3:nth-of-type(9n+3) {
    float: right;
    padding-bottom: 666.6666666667px;
    width: 333.3333333333px;
  }
  .overview--square .overview__item.cols-3:nth-of-type(9n+3) {
    padding-bottom: 333.3333333333px;
  }
}
@media only screen and (min-width: 1024px) {
  .overview__item.cols-4 {
    padding-bottom: 250px;
    width: 250px;
  }
  .overview__item.cols-4--empty {
    display: block;
  }
  .overview__item.cols-4:nth-of-type(9n+2), .overview__item.cols-4:nth-of-type(9n+4) {
    width: 500px;
  }
  .overview--square .overview__item.cols-4:nth-of-type(9n+2), .overview--square .overview__item.cols-4:nth-of-type(9n+4) {
    width: 250px;
  }
  .overview__item.cols-4:nth-of-type(9n+4) {
    float: right;
    padding-bottom: 500px;
    width: 250px;
  }
  .overview--square .overview__item.cols-4:nth-of-type(9n+4) {
    padding-bottom: 250px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .overview__item {
    opacity: 0;
  }
  .overview.in-view {
    --vertical-animation-translate: 10%;
  }
  .overview.in-view .overview__item {
    animation: fade-in-to-top cubic-bezier(0.25, 0.46, 0.45, 0.94) 500ms 250ms forwards;
  }
  .overview.in-view .overview__item:nth-child(1) {
    animation-delay: 0s;
  }
  .overview.in-view .overview__item:nth-child(2) {
    animation-delay: 0.15s;
  }
  .overview.in-view .overview__item:nth-child(3) {
    animation-delay: 0.3s;
  }
  .overview.in-view .overview__item:nth-child(4) {
    animation-delay: 0.45s;
  }
  .overview.in-view .overview__item:nth-child(5) {
    animation-delay: 0.6s;
  }
  .overview.in-view .overview__item:nth-child(6) {
    animation-delay: 0.75s;
  }
  .overview.in-view .overview__item:nth-child(7) {
    animation-delay: 0.9s;
  }
  .overview.in-view .overview__item:nth-child(8) {
    animation-delay: 1.05s;
  }
  .overview.in-view .overview__item:nth-child(9) {
    animation-delay: 1.2s;
  }
  .overview.in-view .overview__item:nth-child(10) {
    animation-delay: 1.35s;
  }
  .overview.in-view .overview__item:nth-child(11) {
    animation-delay: 1.5s;
  }
  .overview.in-view .overview__item:nth-child(12) {
    animation-delay: 1.65s;
  }
  .overview.in-view .overview__item:nth-child(13) {
    animation-delay: 1.8s;
  }
  .overview.in-view .overview__item:nth-child(14) {
    animation-delay: 1.95s;
  }
  .overview.in-view .overview__item:nth-child(15) {
    animation-delay: 2.1s;
  }
  .overview.in-view .overview__item:nth-child(16) {
    animation-delay: 2.25s;
  }
  .overview.in-view .overview__item:nth-child(17) {
    animation-delay: 2.4s;
  }
  .overview.in-view .overview__item:nth-child(18) {
    animation-delay: 2.55s;
  }
  .overview.in-view .overview__item:nth-child(19) {
    animation-delay: 2.7s;
  }
  .overview.in-view .overview__item:nth-child(20) {
    animation-delay: 2.85s;
  }
  .overview.in-view .overview__item:nth-child(21) {
    animation-delay: 3s;
  }
  .overview.in-view .overview__item:nth-child(22) {
    animation-delay: 3.15s;
  }
  .overview.in-view .overview__item:nth-child(23) {
    animation-delay: 3.3s;
  }
  .overview.in-view .overview__item:nth-child(24) {
    animation-delay: 3.45s;
  }
  .overview.in-view .overview__item:nth-child(25) {
    animation-delay: 3.6s;
  }
  .overview.in-view .overview__item:nth-child(26) {
    animation-delay: 3.75s;
  }
  .overview.in-view .overview__item:nth-child(27) {
    animation-delay: 3.9s;
  }
  .overview.in-view .overview__item:nth-child(28) {
    animation-delay: 4.05s;
  }
  .overview.in-view .overview__item:nth-child(29) {
    animation-delay: 4.2s;
  }
  .overview.in-view .overview__item:nth-child(30) {
    animation-delay: 4.35s;
  }
  .overview.in-view .overview__item:nth-child(31) {
    animation-delay: 4.5s;
  }
  .overview.in-view .overview__item:nth-child(32) {
    animation-delay: 4.65s;
  }
  .overview.in-view .overview__item:nth-child(33) {
    animation-delay: 4.8s;
  }
  .overview.in-view .overview__item:nth-child(34) {
    animation-delay: 4.95s;
  }
  .overview.in-view .overview__item:nth-child(35) {
    animation-delay: 5.1s;
  }
  .overview.in-view .overview__item:nth-child(36) {
    animation-delay: 5.25s;
  }
  .overview.in-view .overview__item:nth-child(37) {
    animation-delay: 5.4s;
  }
  .overview.in-view .overview__item:nth-child(38) {
    animation-delay: 5.55s;
  }
  .overview.in-view .overview__item:nth-child(39) {
    animation-delay: 5.7s;
  }
  .overview.in-view .overview__item:nth-child(40) {
    animation-delay: 5.85s;
  }
  .overview.in-view .overview__item:nth-child(41) {
    animation-delay: 6s;
  }
  .overview.in-view .overview__item:nth-child(42) {
    animation-delay: 6.15s;
  }
  .overview.in-view .overview__item:nth-child(43) {
    animation-delay: 6.3s;
  }
  .overview.in-view .overview__item:nth-child(44) {
    animation-delay: 6.45s;
  }
  .overview.in-view .overview__item:nth-child(45) {
    animation-delay: 6.6s;
  }
  .overview.in-view .overview__item:nth-child(46) {
    animation-delay: 6.75s;
  }
  .overview.in-view .overview__item:nth-child(47) {
    animation-delay: 6.9s;
  }
  .overview.in-view .overview__item:nth-child(48) {
    animation-delay: 7.05s;
  }
  .overview.in-view .overview__item:nth-child(49) {
    animation-delay: 7.2s;
  }
  .overview.in-view .overview__item:nth-child(50) {
    animation-delay: 7.35s;
  }
  .overview.in-view .overview__item:nth-child(51) {
    animation-delay: 7.5s;
  }
  .overview.in-view .overview__item:nth-child(52) {
    animation-delay: 7.65s;
  }
  .overview.in-view .overview__item:nth-child(53) {
    animation-delay: 7.8s;
  }
  .overview.in-view .overview__item:nth-child(54) {
    animation-delay: 7.95s;
  }
  .overview.in-view .overview__item:nth-child(55) {
    animation-delay: 8.1s;
  }
  .overview.in-view .overview__item:nth-child(56) {
    animation-delay: 8.25s;
  }
  .overview.in-view .overview__item:nth-child(57) {
    animation-delay: 8.4s;
  }
  .overview.in-view .overview__item:nth-child(58) {
    animation-delay: 8.55s;
  }
  .overview.in-view .overview__item:nth-child(59) {
    animation-delay: 8.7s;
  }
  .overview.in-view .overview__item:nth-child(60) {
    animation-delay: 8.85s;
  }
  .overview.in-view .overview__item:nth-child(61) {
    animation-delay: 9s;
  }
  .overview.in-view .overview__item:nth-child(62) {
    animation-delay: 9.15s;
  }
  .overview.in-view .overview__item:nth-child(63) {
    animation-delay: 9.3s;
  }
  .overview.in-view .overview__item:nth-child(64) {
    animation-delay: 9.45s;
  }
  .overview.in-view .overview__item:nth-child(65) {
    animation-delay: 9.6s;
  }
  .overview.in-view .overview__item:nth-child(66) {
    animation-delay: 9.75s;
  }
  .overview.in-view .overview__item:nth-child(67) {
    animation-delay: 9.9s;
  }
  .overview.in-view .overview__item:nth-child(68) {
    animation-delay: 10.05s;
  }
  .overview.in-view .overview__item:nth-child(69) {
    animation-delay: 10.2s;
  }
  .overview.in-view .overview__item:nth-child(70) {
    animation-delay: 10.35s;
  }
  .overview.in-view .overview__item:nth-child(71) {
    animation-delay: 10.5s;
  }
  .overview.in-view .overview__item:nth-child(72) {
    animation-delay: 10.65s;
  }
  .overview.in-view .overview__item:nth-child(73) {
    animation-delay: 10.8s;
  }
  .overview.in-view .overview__item:nth-child(74) {
    animation-delay: 10.95s;
  }
  .overview.in-view .overview__item:nth-child(75) {
    animation-delay: 11.1s;
  }
  .overview.in-view .overview__item:nth-child(76) {
    animation-delay: 11.25s;
  }
  .overview.in-view .overview__item:nth-child(77) {
    animation-delay: 11.4s;
  }
  .overview.in-view .overview__item:nth-child(78) {
    animation-delay: 11.55s;
  }
  .overview.in-view .overview__item:nth-child(79) {
    animation-delay: 11.7s;
  }
  .overview.in-view .overview__item:nth-child(80) {
    animation-delay: 11.85s;
  }
  .overview.in-view .overview__item:nth-child(81) {
    animation-delay: 12s;
  }
  .overview.in-view .overview__item:nth-child(82) {
    animation-delay: 12.15s;
  }
  .overview.in-view .overview__item:nth-child(83) {
    animation-delay: 12.3s;
  }
  .overview.in-view .overview__item:nth-child(84) {
    animation-delay: 12.45s;
  }
  .overview.in-view .overview__item:nth-child(85) {
    animation-delay: 12.6s;
  }
  .overview.in-view .overview__item:nth-child(86) {
    animation-delay: 12.75s;
  }
  .overview.in-view .overview__item:nth-child(87) {
    animation-delay: 12.9s;
  }
  .overview.in-view .overview__item:nth-child(88) {
    animation-delay: 13.05s;
  }
  .overview.in-view .overview__item:nth-child(89) {
    animation-delay: 13.2s;
  }
  .overview.in-view .overview__item:nth-child(90) {
    animation-delay: 13.35s;
  }
  .overview.in-view .overview__item:nth-child(91) {
    animation-delay: 13.5s;
  }
  .overview.in-view .overview__item:nth-child(92) {
    animation-delay: 13.65s;
  }
  .overview.in-view .overview__item:nth-child(93) {
    animation-delay: 13.8s;
  }
  .overview.in-view .overview__item:nth-child(94) {
    animation-delay: 13.95s;
  }
  .overview.in-view .overview__item:nth-child(95) {
    animation-delay: 14.1s;
  }
  .overview.in-view .overview__item:nth-child(96) {
    animation-delay: 14.25s;
  }
  .overview.in-view .overview__item:nth-child(97) {
    animation-delay: 14.4s;
  }
  .overview.in-view .overview__item:nth-child(98) {
    animation-delay: 14.55s;
  }
  .overview.in-view .overview__item:nth-child(99) {
    animation-delay: 14.7s;
  }
  .overview.in-view .overview__item:nth-child(100) {
    animation-delay: 14.85s;
  }
}

.pagination__header {
  margin: 4rem 24px 0.7rem;
}
@media only screen and (min-width: 768px) {
  .pagination__header {
    margin: 4rem 0 0.7rem;
  }
}
.pagination__heading {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .pagination__heading {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .pagination__heading {
    font-size: 2.5rem;
  }
}
.pagination__showmore {
  padding: 2rem 0 4rem;
  text-align: center;
}
.pagination__showmore.is-hidden {
  display: none;
}
.pagination__no-results {
  padding: 3rem 1.5rem;
}

.people__title {
  padding: 1rem 24px;
}
.people__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media only screen and (min-width: 480px) {
  .people__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 768px) {
  .people__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.people-item:focus .people-item__image--default, .people-item:hover .people-item__image--default {
  opacity: 0;
}
.people-item:focus .people-item__image--no-hover, .people-item:hover .people-item__image--no-hover {
  opacity: 1;
}
.people-item:focus .people-item__text--hover, .people-item:hover .people-item__text--hover {
  opacity: 1;
  transform: translateY(0);
}
.people-item__media {
  aspect-ratio: 2/3;
  background-color: #959595;
  position: relative;
  width: 100%;
}
.people-item__image {
  left: 0;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}
.people-item__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.people-item__content {
  height: 135px;
  overflow: hidden;
  position: relative;
}
.people-item__text {
  background-color: #434343;
  color: #fff;
  height: 100%;
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 100%;
}
.people-item__text--hover {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.3s, opacity 0.3s;
}
.people-item__quote::after, .people-item__quote::before {
  content: '"';
}
.people-item__title {
  font-size: 1.25rem;
}
@media only screen and (min-width: 768px) {
  .people-item__title {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .people-item__title {
    font-size: 1.75rem;
  }
}
.people-item__title {
  margin-bottom: 0.5rem;
}
.people-item--blue .people-item__text {
  background-color: #009bd4;
}
.people-item--light-blue .people-item__text {
  background-color: #8dd8f8;
  color: #434343;
}
.people-item--white .people-item__text {
  background-color: #fff;
  color: #434343;
}
@media only screen and (min-width: 480px) {
  .people-item__content {
    height: 175px;
  }
}
@media only screen and (min-width: 1024px) {
  .people-item__content {
    height: 165px;
  }
}

/*
    In order to make this mixin work in all browsers you need to encode your svg element, and only the SVG element, starting from <svg>... till </svg>
    Read about this issue here: http://stackoverflow.com/questions/10768451/inline-svg-in-css

    how to use: content: inline-svg(string);
*/
/*
    JacobDB - https://gist.github.com/JacobDB/0ffffaf8e772c12acf7102edb8a302be
*/
/*
    JacobDB - https://gist.github.com/JacobDB/0ffffaf8e772c12acf7102edb8a302be
*/
.popover {
  inset: 0;
  opacity: 0;
  position: fixed;
  transition: opacity 0.2s;
  z-index: 50;
}
.popover__mask {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.popover__content {
  left: 50%;
  margin: 0 auto;
  max-height: 100%;
  max-width: 758px;
  overflow: hidden auto;
  padding: 50px 24px 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.popover__close {
  background-color: transparent;
  cursor: pointer;
  height: 40px;
  padding: 0.6rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.2s;
  width: 40px;
  z-index: 2;
}
.popover__close::before {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23fff%22%20viewBox%3D%220%200%20357%20357%22%3E%3Cpath%20d%3D%22M357%2035.7L321.3%200%20178.5%20142.8%2035.7%200%200%2035.7l142.8%20142.8L0%20321.3%2035.7%20357l142.8-142.8L321.3%20357l35.7-35.7-142.8-142.8z%22%2F%3E%3C%2Fsvg%3E");
}
.popover__close span {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.popover__close:hover {
  background-color: #000;
}
.popover.is-active {
  opacity: 1;
}
.popover--close-hidden .popover__close {
  display: none;
}
.popover--sm .popover__content {
  max-width: 540px;
}
.popover--wide .popover__content {
  max-width: 1280px;
}
.popover .media--video {
  opacity: 0;
  transform: translateY(-100px);
  transition: opacity 0.1s, transform 0.2s;
}
.popover .media--video.is-loaded {
  opacity: 1;
  transform: translateY(0);
}
.popover .donate-widget {
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .popover__close {
    height: 50px;
    padding: 0.8rem;
    width: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .popover__content {
    padding: 24px;
  }
}

.schedule {
  border-left: 0;
  color: #000;
  font-size: 0.85em;
  font-weight: 700;
  margin: 2rem auto 3rem;
  table-layout: fixed;
  width: 100%;
}
.schedule__wrapper {
  overflow-x: scroll;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .schedule__wrapper {
    overflow-x: visible;
  }
}
.schedule caption {
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .schedule caption {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule caption {
    font-size: 2.5rem;
  }
}
.schedule caption {
  color: #009bd4;
  font-weight: 700;
}
.schedule__column {
  width: 130px;
}
.schedule__column--time {
  width: 60px;
}
.schedule__head-cell {
  background-color: #000;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.schedule__head-cell--empty {
  background-color: #fff;
}
.schedule__time {
  background-color: #fff;
  color: #000;
  left: 0;
  position: sticky;
  vertical-align: top;
  z-index: 2;
}
.schedule__break {
  font-size: 0.85em;
  text-transform: uppercase;
}
.schedule__talk {
  background-color: #f9f9f9;
  display: table-cell;
  padding: 15px;
  position: relative;
  transition: background-color 100ms;
  vertical-align: top;
}
.schedule__talk:hover {
  background-color: rgb(210.75, 210.75, 210.75);
}
.schedule__talk--color1 {
  background-color: #8dd8f8;
}
.schedule__talk--color1:hover {
  background-color: rgb(68.9256198347, 191.3429752066, 243.5743801653);
}
.schedule__talk--color2 {
  background-color: #c7eafc;
}
.schedule__talk--color2:hover {
  background-color: rgb(126.3898305085, 206.7711864407, 248.1101694915);
}
.schedule__talk--color3 {
  background-color: #ffa182;
}
.schedule__talk--color3:hover {
  background-color: rgb(255, 103.472, 53.5);
}
.schedule__talk--color4 {
  background-color: #fac9b8;
}
.schedule__talk--color4:hover {
  background-color: rgb(244.9671052632, 146.6447368421, 112.5328947368);
}
.schedule__talk--color5 {
  background-color: #e1e2e3;
}
.schedule__talk--color5:hover {
  background-color: rgb(185.4310344828, 187.75, 190.0689655172);
}
.schedule__talk--color6 {
  background-color: #ffdc5c;
}
.schedule__talk--color6:hover {
  background-color: rgb(255, 203.5736196319, 15.5);
}
.schedule__link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.schedule__link span {
  display: none;
}
.schedule tr th {
  border: 5px solid #fff;
  border-left: 0;
  padding: 1em;
}
.schedule td {
  border: 5px solid #fff;
  padding: 1em;
}

.legend__items {
  display: flex;
  flex-flow: row wrap;
}
.legend__item {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: 700;
  margin: 5px 3px;
  padding: 10px 5px;
  text-align: center;
  width: 240px;
}
.legend__item--color1 {
  background-color: #8dd8f8;
}
.legend__item--color2 {
  background-color: #c7eafc;
}
.legend__item--color3 {
  background-color: #ffa182;
}
.legend__item--color4 {
  background-color: #fac9b8;
}
.legend__item--color5 {
  background-color: #e1e2e3;
}
.legend__item--color6 {
  background-color: #ffdc5c;
}

.searchbar {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.searchbar.is-hidden {
  display: none;
}
.searchbar__label {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .searchbar__label {
    letter-spacing: 0.75px;
  }
}
.searchbar__label {
  color: #434343;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.searchbar__field {
  position: relative;
  width: 100%;
}
.searchbar__input {
  background: #fff;
}
.searchbar__input[type=search] {
  border: 0;
  border-radius: 2px;
  color: #000;
  font-size: 1.25rem;
  height: 40px;
  line-height: 40px;
  padding: 0 3rem 0 1rem;
  width: 100%;
}
.searchbar__btn {
  height: 36px;
  position: absolute;
  right: 2px;
  text-align: center;
  top: 2px;
  width: 40px;
}
.searchbar__btn .icon {
  fill: #434343 !important;
  height: 20px;
  width: 20px;
}
.searchbar.theme--white {
  background: transparent;
}
.searchbar.theme--white .searchbar__btn {
  background: #fff;
}
.searchbar.theme--white .searchbar__btn .icon {
  fill: #009bd4 !important;
}
.searchbar.theme--white .searchbar__input {
  border: 2px solid #009bd4;
}

.search-results {
  padding: 2rem 24px;
}
.search-results__list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 800px;
}
.search-results__item {
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  position: relative;
}
.search-results__item::before {
  background-color: #000;
  content: "";
  display: block;
  font-size: 1em;
  height: 12px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 12px;
}
.search-results__link {
  color: #000;
}
.search-results__title {
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .search-results__title {
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .search-results__title {
    font-size: 2rem;
  }
}
.search-results__title {
  color: #009bd4;
  margin-bottom: 0.3rem;
}
.search-results__category {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .search-results__category {
    letter-spacing: 0.75px;
  }
}
.search-results__category {
  font-size: 1rem;
}
@media only screen and (min-width: 768px) {
  .search-results__category {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .search-results__category {
    font-size: 1.5rem;
  }
}
.search-results__category {
  margin-bottom: 0.3rem;
}
.search-results__description {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}
.search-results__no-results {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .search-results__no-results {
    letter-spacing: 0.75px;
  }
}
.search-results__no-results {
  font-size: 1.25rem;
}
@media only screen and (min-width: 768px) {
  .search-results__no-results {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .search-results__no-results {
    font-size: 1.75rem;
  }
}
.search-results__no-results {
  margin-bottom: 2rem;
}

.section {
  padding: 35px 0;
}
.section__button {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.section__color {
  line-height: 50px;
  margin-bottom: 0.5rem;
}
.section__color span {
  float: right;
}
.section__color::before {
  content: "";
  float: left;
  height: 50px;
  line-height: 50px;
  margin-right: 20px;
  width: 50px;
}
.section__color--blue::before {
  background: #009bd4;
}
.section__color--lightblue::before {
  background: #8dd8f8;
}
.section__color--lightestblue::before {
  background: #c7eafc;
}
.section__color--grey::before {
  background: #959595;
}
.section__color--lightgrey::before {
  background: #e1e2e3;
}
.section__color--darkgrey::before {
  background: #434343;
}
.section__color--black::before {
  background: #000;
}
.section__color--white::before {
  background: #fff;
  border: solid 1px #959595;
}
@media only screen and (min-width: 768px) {
  .section__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
.section__item {
  margin-bottom: 5rem;
}
@media only screen and (min-width: 768px) {
  .section__item {
    width: 45%;
  }
}
.section__item:last-of-type {
  margin: 0;
}
.section__item--full {
  width: 100%;
}
.section__header {
  margin-bottom: 1rem;
}
.section__heading {
  color: #009bd4;
  margin-bottom: 1rem;
}
.section__block {
  margin-bottom: 1rem;
}
.section__block:last-of-type {
  margin: 0;
}

.site {
  display: flex;
  min-height: 100%;
}
.site__container {
  margin: 0 auto;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .site__container {
    width: 758px;
  }
}
@media only screen and (min-width: 1024px) {
  .site__container {
    width: 1000px;
  }
}
.site__container {
  display: flex;
  flex-direction: column;
}
.site__header {
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  transition: transform 400ms;
  width: 100%;
  z-index: 10;
}
.site__header.is-hidden {
  transform: translateY(-100%);
}
@media only screen and (min-width: 768px) {
  .site__header {
    width: 758px;
  }
}
@media only screen and (min-width: 1024px) {
  .site__header {
    position: relative;
    width: auto;
  }
}
.site__content {
  flex: 1 1 auto;
  padding-top: 72px;
}
@media only screen and (min-width: 1024px) {
  .site__content {
    padding: 0;
  }
}
.site__content .sections--main .sections--right .section {
  background-color: #009bd4;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .site__content .has-sidebar .sections--main .sections--right {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .site__content .has-sidebar .sections--main {
    display: flex;
  }
  .site__content .has-sidebar .sections--main .content {
    flex: 3;
  }
  .site__content .has-sidebar .sections--main .sections--right {
    flex: 1;
  }
}
.site__footer {
  flex: 0 0 auto;
}

.social {
  margin-bottom: 1rem;
}
.social__title {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.social__title--hide {
  visibility: hidden;
}
.social__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social__item {
  display: inline-block;
  margin-right: 1px;
  transition: background-color 0.2s;
  vertical-align: top;
}
.social__item:hover {
  background-color: #009bd4;
}
.social__link {
  display: flex;
  height: 32px;
  width: 32px;
}
.social__icon {
  fill: #fff;
  height: 16px;
  margin: auto;
  width: 16px;
}
.social__icon--youtube {
  height: 20px;
  width: 20px;
}
.social.theme--dark-grey {
  background: transparent;
}
.social.theme--dark-grey .social__title {
  color: #434343;
}
.social.theme--dark-grey .social__item {
  background-color: #434343;
}
.social.theme--dark-grey .social__item:hover {
  background-color: #009bd4;
}
.social.theme--white {
  background: transparent;
}
.social.theme--white .social__item {
  background-color: #fff;
}
.social.theme--white .social__item:hover {
  background-color: #009bd4;
}

.tag {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  .tag {
    letter-spacing: 0.75px;
  }
}
.tag {
  align-items: center;
  display: flex;
}
.tag__icon {
  background: #009bd4;
  display: inline-block;
  height: 24px;
  position: relative;
  transition: background 0.3s;
  width: 24px;
}
.tag__svg {
  fill: #fff;
  height: 16px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: fill 0.3s;
  width: 16px;
}
.tag__text {
  color: #000;
  margin-left: 0.75rem;
  transition: color 0.3s;
}
.tag__close {
  display: none;
}
.tag:hover .tag__text {
  color: #000;
}
.tag--button {
  padding: 0.4rem 0.6rem 0.4rem 0.4rem;
  transition: background 0.3s;
}
.tag--button.is-active {
  background: #009bd4;
}
.tag--button.is-active .tag__icon {
  background: #fff;
}
.tag--button.is-active .tag__svg {
  fill: #009bd4;
}
.tag--button.is-active .tag__text {
  color: #fff;
}
.tag--button.is-active:hover .tag__text {
  color: #fff;
}
.tag--removable {
  padding: 0;
}
.tag--removable .tag__icon {
  display: none;
}
.tag--removable .tag__close {
  display: block;
  fill: #fff;
  height: 14px;
  transition: fill 0.3s;
  width: 14px;
}
.tag--reversed .tag__icon {
  background: #fff;
}
.tag--reversed .tag__svg {
  fill: #009bd4;
}
.tag--reversed .tag__text {
  color: #000;
}
.tag--reversed:hover .tag__text {
  color: #fff;
}
.tag--reversed.tag--button.is-active {
  background: #fff;
}
.tag--reversed.tag--button.is-active .tag__icon {
  background: #009bd4;
}
.tag--reversed.tag--button.is-active .tag__svg {
  fill: #fff;
}
.tag--reversed.tag--button.is-active .tag__text {
  color: #000;
}
.tag--reversed.tag--button.is-active:hover .tag__text {
  color: #000;
}

.tags {
  margin-bottom: 1rem;
  max-width: 400px;
}
.tags__title {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.tags__title--hide {
  visibility: hidden;
}
.tags__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}
.tags__item {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: 700;
  margin: 2px;
  text-transform: uppercase;
  vertical-align: top;
}
.tags__link {
  display: block;
  max-width: none;
  padding: 0.25rem 0.5rem;
}
.tags.theme--white {
  background: transparent;
}
.tags.theme--white .tags__title {
  color: #fff;
}
.tags.theme--white .tags__item {
  background-color: #fff;
  color: #009bd4;
}
.tags.theme--white .tags__item:hover {
  background-color: rgb(229.5, 229.5, 229.5);
}
.tags.theme--light-grey {
  background: transparent;
}
.tags.theme--light-grey .tags__item {
  background-color: #e1e2e3;
  color: #000;
}
.tags.theme--light-grey .tags__item:hover {
  background-color: rgb(198.6206896552, 200.5, 202.3793103448);
}

.text-block {
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .text-block {
    min-height: 333px;
  }
  .text-block--medium {
    min-height: 500px;
  }
}
.text-block--hover:hover .text-block__image {
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .text-block--hover:hover .text-block__image {
    transform: scale(1.25);
    transition: transform 1.5s;
  }
}
.text-block__btn {
  color: inherit;
  position: relative;
}
.text-block__btn::before {
  background-color: inherit;
}
.text-block__content {
  margin-bottom: 2.1rem;
  max-width: 800px;
  overflow: hidden;
  padding: 0 1.5rem;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .text-block__content {
    margin-bottom: 2.5rem;
  }
}
.text-block__media {
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 480px) {
  .text-block__media {
    padding-bottom: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .text-block__media {
    height: 100%;
    overflow: hidden;
    padding-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
}
.text-block__image {
  display: block;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .text-block__image {
    left: 0;
    top: 0;
    transform: translate(0);
  }
}
@media only screen and (min-width: 1024px) {
  .text-block__image {
    left: auto;
    position: static;
    top: auto;
  }
}
.text-block__header {
  padding: 1.75rem 0 1rem;
  position: relative;
}
.text-block__heading {
  font-size: 1.4rem;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .text-block__heading {
    font-size: 1.7rem;
  }
}
.text-block__subheading {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}
.text-block__footer {
  bottom: 0;
  padding: 1.5rem 1.75rem;
  position: absolute;
  width: 100%;
}
.text-block__link {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.text-block__text {
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .text-block.text-block--img .text-block__content {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }
  .text-block.text-block--img .text-block__media {
    display: block;
    height: 100%;
    width: 50%;
  }
  .text-block.text-block--img.text-block--img-left .text-block__content {
    left: auto;
    right: 0;
  }
  .text-block.text-block--img.text-block--img-left .text-block__media {
    left: 0;
    right: auto;
  }
  .text-block.text-block--img.text-block--small {
    padding-bottom: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--small .text-block__content {
    width: 666.6666666667px;
  }
  .text-block.text-block--img.text-block--small .text-block__media {
    width: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--small .text-block--img .text-block__content {
    width: 666.6666666667px;
  }
  .text-block.text-block--img.text-block--small .text-block--img .text-block__media {
    width: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--medium {
    padding-bottom: 500px;
  }
  .text-block.text-block--img.text-block--medium .text-block__content {
    width: 500px;
  }
  .text-block.text-block--img.text-block--medium .text-block__media {
    width: 500px;
  }
  .text-block.text-block--img.text-block--medium .text-block__heading {
    font-size: 2rem;
  }
  .text-block.text-block--img.text-block--medium .text-block__subheading {
    font-size: 1.3rem;
  }
  .text-block.text-block--img.text-block--medium .text-block--img .text-block__content {
    width: 500px;
  }
  .text-block.text-block--img.text-block--medium .text-block--img .text-block__media {
    width: 500px;
  }
  .text-block.text-block--img.text-block--large {
    padding-bottom: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--large .text-block__content {
    width: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--large .text-block__media {
    width: 666.6666666667px;
  }
  .text-block.text-block--img.text-block--large .text-block--img .text-block__content {
    width: 333.3333333333px;
  }
  .text-block.text-block--img.text-block--large .text-block--img .text-block__media {
    width: 666.6666666667px;
  }
}

.video__close {
  padding: 0.6rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.video__close:hover {
  background: rgba(255, 255, 255, 0.1);
}
.video__close-icon {
  fill: #fff;
  height: 20px;
  width: 20px;
}
@media only screen and (min-width: 768px) {
  .video__close-icon {
    height: 30px;
    width: 30px;
  }
}
.video__container {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
}
@media only screen and (min-width: 480px) {
  .video__container {
    max-width: 390px;
  }
}
@media only screen and (min-width: 768px) {
  .video__container {
    max-width: 560px;
  }
}
.video__container-inner {
  padding-top: 56.25%;
}
.video__overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 180ms;
  z-index: 2;
}
.is-active .video__overlay {
  opacity: 1;
  pointer-events: all;
}
.video__play-btn {
  cursor: pointer;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 180ms, width 0.2s;
  width: 100px;
}
.video__play-btn:hover {
  width: 125px;
}
.is-active .video__play-btn {
  opacity: 0;
}
.video__play-btn-icon {
  fill: #fff;
  height: auto;
  width: 100%;
}
.video__player {
  height: 100%;
  inset: 0;
  opacity: 0;
  position: absolute;
  transform: translateY(-50px);
  transition: opacity 250ms, transform 180ms;
  width: 100%;
}
.video__player.is-loaded {
  opacity: 1;
  transform: translateY(0);
}

@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}