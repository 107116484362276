@use '../config' as *;
@use '../mixins/container';
@use '../mixins/screen';
@use '../mixins/property-override';

.site {
    display: flex;
    min-height: 100%;

    &__container {
        @include container.apply;

        @include property-override.apply {
            display: flex;
            flex-direction: column;
        }
    }

    &__header {
        flex: 0 0 auto;
        position: fixed;
        top: 0;
        transition: transform 400ms;
        width: 100%;
        z-index: 10;

        &.is-hidden {
            transform: translateY(-100%);
        }

        @include screen.apply($screen-simple) {
            width: $screen-simple - 10px;
        }

        @include screen.apply($screen-normal) {
            position: relative;
            width: auto;
        }
    }

    &__content {
        flex: 1 1 auto;
        padding-top: $h-header;

        @include screen.apply($screen-normal) {
            padding: 0;
        }

        .sections--main {
            .sections--right {
                .section {
                    background-color: $c-blue;
                    color: $c-white;
                }
            }
        }

        @include screen.apply($screen-simple) {
            .has-sidebar {
                .sections--main {
                    .sections--right {
                        width: 100%;
                    }
                }
            }
        }

        @include screen.apply($screen-normal) {
            .has-sidebar {
                .sections--main {
                    display: flex;

                    .content {
                        flex: 3;
                    }

                    .sections--right {
                        flex: 1;
                    }
                }
            }
        }

    }

    &__footer {
        flex: 0 0 auto;
    }
}
