@use '../config' as *;
@use '../mixins/screen';

.header {
    align-items: center;
    background: $c-white;
    display: flex;
    height: $h-header;
    padding: 0 $p-container;
    position: relative;

    &__heading {
        color: $c-grey--dark;
    }

    &__brand {
        margin-right: auto;
    }

    &__logo {
        display: none;
        width: 75px;
    }

    &__logo-text {
        height: $h-logo-text;
        width: $w-logo-text;
    }

    &__payoff {
        display: none;
        padding-top: 5px;
        width: 75px;
    }

    &__link {
        display: block;
    }

    &__main {
        box-shadow: $shadow-menu;
        left: 0;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 100%;
        transition: max-height .4s cubic-bezier(.4, 0, .2, 1);
        width: 100%;
        z-index: 10;

        &.is-open {
            max-height: 1000px;
            transition: max-height .8s cubic-bezier(.4, 0, .2, 1);
        }
    }

    &__secondary {
        order: 2;
    }

    @include screen.apply($screen-simple) {
        padding: 0;
    }

    @include screen.apply($screen-normal) {
        &__brand {
            line-height: $h-header--full - 8px;
        }

        &__main {
            box-shadow: none;
            display: block;
            margin-right: 2rem;
            max-height: none;
            order: 2;
            overflow: visible;
            position: static;
            top: auto;
            width: auto;
        }

        &__secondary {
            order: 3;
        }
    }

    @include screen.apply(1230px) {
        justify-content: flex-end;

        &__brand {
            bottom: auto;
            left: (-$w-logo + -32px);
            line-height: 32px;
            margin: 1rem 0 0;
            position: absolute;
            top: -1px;
        }

        &__logo,
        &__payoff {
            display: block;
        }

        &__logo-text {
            display: none;
        }
    }
}
