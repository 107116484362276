@use '../config' as *;
@use '../mixins/list-reset';
@use '../mixins/screen';

.people {
    &__title {
        padding: 1rem $p-container
    }

    &__list {
        @include list-reset.apply;

        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    @include screen.apply($screen-minimal) {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include screen.apply($screen-simple) {
        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
