@use '../config' as *;
@use '../mixins/object-fit';
@use '../mixins/screen';
@use '../mixins/typography';
@use '../mixins/property-override';

.banner-video {
    background: $c-grey;
    margin-bottom: -1px;
    min-height: 300px;
    overflow: hidden;
    position: relative;

    &__content {
        color: $c-white;
        max-width: 800px;
        padding: 1.75rem $p-container 2.5rem;
        position: relative;
        width: 100%;
    }

    &__title {
        @include typography.heading-1;

        @include property-override.apply {
            color: $c-white;
            margin-bottom: 1rem;
            text-shadow: 0 0 30px $shadow-text;
        }
    }

    &__subtitle {
        @include typography.heading-1;

        @include property-override.apply {
            color: $c-blue;
            text-shadow: 0 0 30px $shadow-text;
        }
    }

    &__text {
        font-size: 1.125rem;
        text-shadow: 0 0 30px $shadow-text;
    }

    &__button {
        margin-top: 1rem;
    }

    &__image,
    &__video {
        @include object-fit.apply;

        left: 0;
        position: absolute;
        top: 0;
    }

    @include screen.apply($screen-simple) {
        aspect-ratio: 1 / 0.5;
        min-height: 300px;
    }
}
