@use '../config' as *;

.legacy-warning {
    background: $c-blue;
    height: 100%;
    inset: 0;
    position: fixed;
    width: 100%;
    z-index: 9999;

    &__container {
        display: flex;
        height: 100%;
        width: 100%;
    }

    &__content {
        background: $c-white;
        margin: auto;
        max-width: 400px;
        padding: 1.5rem;
        text-align: center;
    }

    &__heading {
        margin: 0 0 .7rem;
    }
}
